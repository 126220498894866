import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { Top } from '~pages/-feature/Top';
import { InnerWrapper, OuterWrapper } from '~pages/-feature/typography/Typography';
import { CreditCards } from '~pages/legal/CreditCards';
import { Page, trackPageView } from '~utils/tracking';

export const PagePrivacy = () => {
  const { logEvent } = useAmplitude();
  const [policy, setPolicy] = useState('');

  useEffect(() => trackPageView(Page.privacy), []);

  useEffect(() => {
    axios.get(`https://www.iubenda.com/api/privacy-policy/97285631`).then((res) => {
      if (!res?.data?.content) {
        logEvent('error::privacy_policy_content_empty');
      }

      setPolicy(res?.data?.content);
    });
  }, []);

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:privacy" />
      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <Top nav={'home'} />
        <div dangerouslySetInnerHTML={{ __html: policy }} />
        <CreditCards />
      </InnerWrapper>
    </OuterWrapper>
  );
};
