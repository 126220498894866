import React from 'react';
import styled from 'styled-components';
import { Radio } from '~pages/-feature/radio/Radio';
import { SmallRadio } from '~pages/-feature/radio/SmallRadio';

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 500px;
  margin: auto;
`;

interface Props {
  id: string;
  values: number[];
  selectedValue: number;
  onSelect: (value: number) => void;
  smallRadio?: boolean;
}

export const RadioContainer = (props: Props) => {
  const { id, values, selectedValue, onSelect, smallRadio } = props;

  return (
    <>
      <RowWrapper>
        {values.map((v) => {
          return smallRadio ? (
            <SmallRadio key={id + v} radioGroupId={id} value={v} selectedValue={selectedValue} onSelect={onSelect} />
          ) : (
            <Radio key={id + v} radioGroupId={id} value={v} selectedValue={selectedValue} onSelect={onSelect} />
          );
        })}
      </RowWrapper>
      {/*<div>*/}
      {/*  <a>None</a>*/}
      {/*  <a>Custom</a>*/}
      {/*</div>*/}
    </>
  );
};
