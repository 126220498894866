import React, { useEffect } from 'react';
import { LogOnMount } from 'react-amplitude-hooks';
import { supportEmail, supportPhone } from '~constants';
import { Bottom } from '~pages/-feature/Bottom';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper, Paragraph, Title } from '~pages/-feature/typography/Typography';
import { Page, trackPageView } from '~utils/tracking';

export const PageContact = () => {
  useEffect(() => trackPageView(Page.contact), []);

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:contact" />

      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <Top nav={'home'} />
        <Content style={{ textAlign: 'left' }}>
          <Title>Contact Us</Title>

          <Paragraph style={{ paddingTop: 15 }}>
            <b>We look forward to assisting you!</b>
          </Paragraph>

          <Paragraph>
            Please email us at{' '}
            <a style={linkStyle} href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>
            .
          </Paragraph>

          <Paragraph>
            Please text us at{' '}
            <a style={linkStyle} href={`tel:${supportPhone}`}>
              {supportPhone}
            </a>
            .
          </Paragraph>

          <Paragraph>
            <b>Please allow up to 24 hours for a reply. We promise to get back to you and resolve any issues.</b>
          </Paragraph>
        </Content>

        <Bottom justMail={true} />
      </InnerWrapper>
    </OuterWrapper>
  );
};
