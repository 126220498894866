import { useCallback, useMemo } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useContext } from '~hooks/useContext/useContext';
import { useTotalCents } from '~hooks/useTotalCents';
import { ConfigOptionItem } from '~models/Config';
import { OrderType } from '~models/OrderType';
import { trackBeginCheckout } from '~pages/main/payment/trackBeginCheckout';
import { isValidUsernameFormat } from '~utils/isValidUsernameFormat';

/**
 */
export const usePrepaymentValidation = (): (() => boolean) => {
  const [state, dispatch] = useContext();
  const { logEvent } = useAmplitude();

  const {
    order,
    track: { campaign }
  } = state;
  const { item, posts, cents, username, phone } = order;
  const { platform, orderType } = item;

  const {
    order: {
      error: { setCentsError, setUsernameError }
    }
  } = dispatch;

  const totalCents = useTotalCents(orderType, posts, cents);

  const optionItems: ConfigOptionItem[] = useMemo(() => {
    if (posts && orderType !== OrderType.Follow) return posts.map(() => item);
    return [item];
  }, [posts, item, orderType]);

  const prePaymentValidation: () => boolean = useCallback(() => {
    logEvent('tap_purchase');

    if (totalCents === 0) {
      logEvent('tap_purchase_error', { error: 'price is 0', orderType });
      if (posts && orderType !== OrderType.Follow) {
        setCentsError('Please select at least one post.');
      } else {
        setCentsError('Total price must be more than $0');
      }
      return false;
    }

    const isValid = isValidUsernameFormat(username, setUsernameError);

    if (!isValid) {
      logEvent('tap_purchase_error', { error: 'username format invalid' });
      return false;
    }

    // NOTE: doing checkUsernameExistsAndPublic on backend during validate merchant flow

    trackBeginCheckout({ cents, platform, username, campaign, optionItems, logEvent });

    return true;
  }, [cents, platform, username, phone, campaign, optionItems]);

  return prePaymentValidation;
};
