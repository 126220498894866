import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from '~api/getConfig';
import { isProd } from '~constants';
import determineItem from '~contexts/helpers/determineItem';
import determineOrderType from '~contexts/helpers/determineOrderType';
import determinePlatform from '~contexts/helpers/determinePlatform';
import { useContext } from '~hooks/useContext/useContext';
import { Config } from '~models/Config';
import { Platform } from '~models/Platform';
import { QualityType } from '~models/QualityType';
import { getCookie, setCookie } from '~utils/cookie';

function useSetup() {
  const [state, dispatch] = useContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  if (!isProd) console.log(state);

  const {
    config: { cid, flow, remoteConfig },
    order: {
      error: { usernameError, phoneError, centsError }
    },
    track: { campaign }
  } = state;

  const {
    config: { setCid, setRemoteConfig },
    order: {
      setItem,
      setUsername,
      cookie: { setUsernameCookieKey },
      error: { setUsernameError, setPhoneError, setCentsError }
    }
  } = dispatch;

  // MARK: save pre-set values of campaign and flow
  useEffect(() => {
    setCookie('campaign', campaign);
    setCookie('flow', flow);
    if (usernameError !== '') setUsernameError('');
    if (phoneError !== '') setPhoneError('');
    if (centsError !== '') setCentsError('');
  }, []);

  // MARK: config
  useEffect(() => {
    if (!remoteConfig) {
      getConfig({ cid: cid })
        .then((config: Config) => {
          if (config.cid) setCookie('cid', config.cid);
          setCid(config.cid ?? 'unknown');
          setRemoteConfig(config);
        })
        .catch((e) => console.error(e));
    }
  }, [cid, remoteConfig]);

  // MARK: order.item
  useEffect(() => {
    // if (loading && remoteConfig) {
    if (remoteConfig) {
      let platformParam, orderTypeParam;

      const parts = window.location.pathname.split('/');
      const pieces = parts.length;

      if (pieces > 1) {
        const isThanks = parts[1] === 'thanks';

        if (isThanks) {
          if (pieces > 2) {
            platformParam = parts[2];
            if (pieces > 3) orderTypeParam = parts[3];
          }
        } else {
          platformParam = parts[1];
          if (pieces > 2) orderTypeParam = parts[2];
        }
      }

      const platform = determinePlatform(platformParam);

      const qualityType = platform === Platform.Gram ? QualityType.Real : QualityType.Bot;

      try {
        const item = determineItem({
          remoteConfig,
          platform,
          orderType: determineOrderType(orderTypeParam),
          qualityType
        });
        setItem(item);
      } catch (e) {
        console.error(e);
        navigate('/error');
      }

      const usernameCookieKey = `${platform.toLowerCase()}_username`;
      setUsernameCookieKey(usernameCookieKey);
      setUsername(getCookie(usernameCookieKey) || '');

      setLoading(false);
    }
  }, [remoteConfig, window.location.pathname]);

  return [loading, setLoading];
}

export default useSetup;
