import { loadStripe } from '@stripe/stripe-js';

export const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

export let backendEndpoint = 'https://api.yofame.com';
// export let backendEndpoint = 'http://localhost:8000';
// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
export let stripePromise = loadStripe(
  'pk_live_51HocR2Ke3TAFjbYEhVBHQ1euC3HJxljUuwhcEYek8QSR0udbNiSE0cUE69afwgjiGk3zPQcUEsVFVOsOQNMU8Uvt002x2rsmmK'
); // LIVE

if (process.env.NODE_ENV === 'development') {
  // backendEndpoint = 'https://api.dev.yofame.com';
  // backendEndpoint = 'http://localhost:8000';
  // stripePromise = loadStripe(
  //   'pk_test_51HocR2Ke3TAFjbYEefwbe6NrIZdZQS5UTOewDuAqoo7LpPgxjpryG2VdqvujdnHW9Z4N9aFJ0QD6SFAhfhTi2hcY00afLhJwUm'
  // ); // TEST
}

export const isProd = process.env.NODE_ENV === 'production';

export const amplitudeKey = '718993b228832dac103aceaca670a0ec';
export const snapPixelId = '62c7410f-561e-4279-875c-914008f398d0';
export const tiktokPixelId = 'CD6ORQRC77U8TNJJ3EAG';

export const supportPhone = '310-861-7560';
export const supportEmail = 'support@yofame.com';
export const supportAddress = 'Dover, DE 19901';

// Flow B
export const babyBlue = '#0299ff';
