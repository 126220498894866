import axios, { AxiosResponse } from 'axios';
import { backendEndpoint, isProd } from '~constants';
import { GetProfileRequest } from '~models/api-request/GetProfileRequest';
import { GetProfileResponse } from '~models/api/GetProfileResponse';

export const getProfile = async ({
  uid,
  platform,
  username,
  phone
}: GetProfileRequest): Promise<GetProfileResponse> => {
  let body: GetProfileRequest = { uid, platform, username, phone };
  if (!isProd) body = { ...body, isDev: true };

  const result: AxiosResponse<GetProfileResponse> = await axios.post(`${backendEndpoint}/api/get-profile`, body);

  return result.data;
};
