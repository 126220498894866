import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { Top } from '~pages/-feature/Top';
import { InnerWrapper, OuterWrapper } from '~pages/-feature/typography/Typography';
import { CreditCards } from '~pages/legal/CreditCards';
import { Page, trackPageView } from '~utils/tracking';

export const PageTerms = () => {
  const { logEvent } = useAmplitude();
  const [terms, setTerms] = useState('');

  useEffect(() => trackPageView(Page.terms), []);

  useEffect(() => {
    axios.get(`https://www.iubenda.com/api/terms-and-conditions/97285631`).then((res) => {
      if (!res?.data?.content) {
        logEvent('error::terms_content_empty');
      }

      setTerms(res?.data?.content);
    });
  }, []);

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:terms" />
      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <Top nav={'home'} />
        <div dangerouslySetInnerHTML={{ __html: terms }} />
        <CreditCards />
      </InnerWrapper>
    </OuterWrapper>
  );
};
