import { useMemo } from 'react';
import { OrderType } from '~models/OrderType';
import { Post } from '~models/Post';

/**
 * Calculates totalCents.
 * Considers multiple post selection.
 *
 * @param orderType
 * @param posts
 * @param cents
 */
export const useTotalCents = (orderType: OrderType, posts: Post[], cents: number) => {
  return useMemo(() => {
    if (posts && orderType !== OrderType.Follow) return cents * posts.length;
    return cents;
  }, [orderType, posts, cents]);
};
