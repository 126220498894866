import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { OptionInput, RadioProps } from '~pages/-feature/radio/Radio';
import { formatNumber } from '~utils/format';

const Wrapper = styled.div``;

const OptionGroup = styled.div`
  display: flex;
  text-align: center;
`;

const FormItem = styled.div`
  position: relative;
  display: inline-block;
`;

const OptionLabel = styled.label`
  display: flex;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-radius: var(--yf-border-radius--pill);
  width: 45px;
  height: 45px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.33s cubic-bezier(0.5, 0, 0, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.2;
  background-color: transparent;
`;

const LabelTitle = styled.div`
  display: flex;
  justify-content: center;
  color: var(--yf-color--black);
  font-variant-numeric: lining-nums;
  white-space: nowrap;
  margin: auto;
  font-size: 22px;
`;

const TextLeft = styled.p`
  outline: none;
`;

export const SmallRadio = (props: RadioProps) => {
  const { radioGroupId, value, selectedValue, onSelect } = props;

  const [selected, setSelected] = useState(false);

  const onChange = useCallback(() => onSelect(value), [onSelect, value]);

  useEffect(() => setSelected(value === selectedValue), [value, selectedValue]);

  return (
    <Wrapper data-id={radioGroupId + '-' + value} data-id-selected={selected} aria-label={value}>
      <FormItem>
        <OptionGroup>
          <OptionInput
            type="radio"
            name={radioGroupId}
            id={radioGroupId + '-' + value}
            value={value}
            onChange={onChange}
            checked={selected}
          />
          <OptionLabel
            htmlFor={radioGroupId + '-' + value}
            selected={selected}
            style={
              selected
                ? {
                    boxShadow: '0 0 0 3px var(--yf-color--blue30)',
                    fontFamily: 'var(--yf-font-combined--medium)'
                  }
                : { boxShadow: '0 0 0 1px var(--yf-color--grey40)' }
            }
          >
            <LabelTitle selected={selected}>
              <TextLeft>{formatNumber(value)}</TextLeft>
            </LabelTitle>
          </OptionLabel>
        </OptionGroup>
      </FormItem>
    </Wrapper>
  );
};
