import gtag from 'ga-gtag';
import React, { useCallback, useEffect, useState } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useContext } from '~hooks/useContext/useContext';
import { SignInData } from '~models/SignInData';
import { Bullets } from '~pages/-feature/bullets/Bullets';
import { SupportBullet } from '~pages/-feature/bullets/SupportBullet';
import TextInput from '~pages/-feature/form/TextInput';
import { Loading } from '~pages/-feature/Loading';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper, SubTitle, Title } from '~pages/-feature/typography/Typography';
import { validPhoneNumber } from '~utils/validPhoneNumber';

export const SignInPage = () => {
  const [state, dispatch] = useContext();
  const { logEvent } = useAmplitude();

  const {
    order: {
      phone,
      error: { phoneError }
    }
  } = state;

  const {
    order: {
      setPhone,
      error: { setPhoneError }
    }
  } = dispatch;

  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState('');

  const handleHotlinkRequest = useCallback(async () => {
    if (redirecting) return;
    gtag('event', 'tap_request_hotlink');
    logEvent('tap_request_hotlink');

    if (!phone || phone.trim() == '') {
      gtag('event', 'tap_request_hotlink_error', { value: 'missing phone' });
      logEvent('tap_request_hotlink_error', { error: 'missing phone' });
      setPhoneError('Phone is required');
      return;
    }

    if (phone !== '' && !validPhoneNumber(phone.trim())) {
      gtag('event', 'tap_request_hotlink_error', { value: 'invalid phone number' });
      logEvent('tap_request_hotlink_error', { error: 'invalid phone number', submittedPhone: phone });
      setPhoneError('Phone number is invalid');
      return;
    }

    setRedirecting(true);
    gtag('event', 'tap_request_hotlink_success', {
      method: 'phone'
    });
    logEvent('tap_request_hotlink_success', { method: 'phone' });

    const signInData: SignInData = {
      email: '',
      phone
    };

    const resultMsg = await dispatch.account?.sendSignInLink(signInData);
    setRedirecting(false);
    if (resultMsg) {
      setMessage(resultMsg);
    }
  }, [dispatch, phone, redirecting]);

  useEffect(() => setPhoneError(''), [phone]);

  useEffect(() => {
    gtag('event', 'page_view', {
      page_location: 'https://yofame.com/sign-in',
      page_path: '/sign-in',
      page_title: 'SignIn'
    });

    gtag('event', 'page_view_signIn');
  }, []);

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LogOnMount eventType="page:signIn" />
        <Top nav={'home'} />

        <Content style={{ paddingBottom: 20 }}>
          <Title style={{ textAlign: 'left' }}>Sign In</Title>

          <SubTitle textAlign={'left'} padding={'10px 0 30px 0'}>
            Say bye to passwords! We will send a link to your phone so you can manage your account.
          </SubTitle>

          {/*<TextInput*/}
          {/*  labelText={'Email Address'}*/}
          {/*  inputId={'EMAIL'}*/}
          {/*  maxLength={50}*/}
          {/*  initialText={email}*/}
          {/*  errorText={emailError}*/}
          {/*  setError={setEmailError}*/}
          {/*  onChange={setEmail}*/}
          {/*/>*/}

          <TextInput
            cookieKey={'Phone Number'}
            labelText={'Phone Number'}
            inputId={'PHONE_NUMBER'}
            maxLength={50}
            style={{ marginBottom: 0 }}
            value={phone}
            errorText={phoneError}
            setError={setPhoneError}
            onChange={setPhone}
          />

          <Bullets
            bullets={[
              SupportBullet,
              'If you are experiencing delivery delays, please know that delivery can sometimes take a few hours to start. ' +
                'If you still have concerns please contact us and we will do our very best to make you satisfied.'
            ]}
          />

          <div className="yf-flex yf-flex--justify-center" style={{ paddingTop: 20, paddingBottom: 25 }}>
            {message}

            <button
              type="button"
              className="yf-link action-trigger--link text-loader--content yf-btn yf-btn--blue yf-btn--full continue-to-payment-btn"
              data-id="btn-navigation"
              data-subtype="btn-navigation--payment"
              onClick={handleHotlinkRequest}
            >
              <span>{redirecting ? 'Please Wait' : 'Get Hotlink'}</span>
              {redirecting && <Loading />}
            </button>
          </div>
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
