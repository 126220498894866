import axios, { AxiosResponse } from 'axios';
import { backendEndpoint, isProd } from '~constants';
import { GetPostsRequest } from '~models/api-request/GetPostsRequest';
import { GetPostsResponse } from '~models/api/GetPostsResponse';

export const getPosts = async ({ uid, platform, username, phone }: GetPostsRequest): Promise<GetPostsResponse> => {
  let body: GetPostsRequest = { uid, platform, username, phone };
  if (!isProd) body = { ...body, isDev: true };

  const result: AxiosResponse<GetPostsResponse> = await axios.post(`${backendEndpoint}/api/get-posts`, body);

  return result.data;
};
