import axios, { AxiosResponse } from 'axios';
import { backendEndpoint, isProd } from '~constants';
import { Config } from '~models/Config';

interface GetConfig {
  cid: string;
}

export const getConfig = async ({ cid }: GetConfig): Promise<Config> => {
  let body: { cid: string; isDev?: boolean } = { cid };
  if (!isProd) body = { ...body, isDev: true };

  const result: AxiosResponse<Config> = await axios.post(`${backendEndpoint}/api/get-config`, body);
  return result.data;
};
