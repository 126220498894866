import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Post } from '~models/Post';

const Wrapper = styled.div`
  flex-grow: 1;
  width: 33%;
  margin-bottom: 10px;
  position: relative;
`;

const Image = styled.img`
  width: 90%;
  border-radius: 4px;
  min-height: 100px;
  border: ${(props) => (props.selected ? '3px solid #3e6ae1' : '')};
  box-shadow: ${(props) =>
    props.selected
      ? 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'
      : ''};
`;

const Likes = styled.div`
  position: absolute;
  bottom: 10px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: bold;
  margin-left: 13px;
  line-height: 14px;
  background: #ffffffcc;
  padding: 0 3px;
`;

const Comments = styled.div`
  position: absolute;
  bottom: 10px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 13px;
  line-height: 14px;
  background: #000000cc;
  color: white;
  border-radius: 2px;
`;

interface PostProps {
  post: Post;
}

const PostElement = (props: PostProps) => {
  const [state, dispatch] = useContext();
  const [selected, setSelected] = useState(false);

  const {
    order: {
      posts,
      item: { orderType }
    }
  } = state;

  const {
    order: { setPosts }
  } = dispatch;

  const { post } = props;

  const onClick = useCallback(() => {
    if (selected) {
      setPosts(posts.filter((p) => p.shortcode !== post.shortcode));
    } else {
      setPosts([...posts, post]);
    }
  }, [selected, posts, post]);

  useEffect(() => setSelected(posts.filter((p) => p.id === post.id).length > 0), [posts]);

  return (
    <Wrapper onClick={onClick}>
      <Image src={post.thumbnail} alt={'post thumbnail'} selected={selected} />
      {orderType === OrderType.Like && post.likes >= 0 && <Likes>❤️ {post.likes}</Likes>}
      {orderType === OrderType.Comment && post.comments >= 0 && <Comments>💬️ {post.comments}</Comments>}
    </Wrapper>
  );
};

export default PostElement;
