import { OrderType } from '~models/OrderType';

const ordering = {}, // map for efficient lookup of sortIndex
  sortOrder = [OrderType.Follow, OrderType.View, OrderType.Like, OrderType.Comment];
for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

const sortMenuItems = (a, b) => {
  return ordering[a] - ordering[b] || a.localeCompare(b);
};

export default sortMenuItems;
