import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnPaymentAuthorized, OnValidateMerchant, PaymentRequest } from 'types/apple-pay';
import { backendEndpoint, isProd } from '~constants';
import { useApplePay } from '~hooks/apple-pay/useApplePay';
import { useApplePaymentRequest } from '~hooks/apple-pay/useApplePaymentRequest';
import { useContext } from '~hooks/useContext/useContext';
import { useIsTesting } from '~hooks/useIsTesting';
import { useTotalCents } from '~hooks/useTotalCents';
import ApplePayButton from '~pages/-feature/apple-pay-button/ApplePayButton';
import { getUid } from '~utils/cookie';

export interface OnValidateMerchantResponse {
  merchantSession: any;
  error: {
    userId: boolean;
    private: boolean;
  };
}

const ApplePay = () => {
  const [state, dispatch] = useContext();
  const navigate = useNavigate();

  const {
    config: { cid },
    order,
    track: { campaign },
    backend: { inFlight }
  } = state;

  const {
    interval,
    item,
    posts,
    cents,
    username,
    error: { centsError }
  } = order;

  const { platform, orderType, quantity } = item;

  const {
    order: {
      error: { setCentsError, setGeneralError }
    }
  } = dispatch;

  const isTesting: boolean = useIsTesting();
  const totalCents: number = useTotalCents(orderType, posts, cents);
  const paymentRequest: PaymentRequest = useApplePaymentRequest({ totalCents, order, cid, campaign });

  // reset errors on appropriate changes
  useEffect(() => (centsError !== '' && totalCents > 0 ? setCentsError('') : (() => {})()), [centsError, totalCents]);
  useEffect(() => setGeneralError(''), [username]);

  const onPaymentAuthorized: OnPaymentAuthorized = async (event, session) => {
    try {
      const requestPayment = await axios.post(`${backendEndpoint}/apple-pay/on-payment-authorized`, {
        payment: event.payment,
        order,
        totalCents,
        uid: getUid(),
        cid,
        campaign
      });

      if (requestPayment.data.success) {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        navigate('/thanks' + location.pathname);
      } else {
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      }
    } catch (e) {
      setGeneralError('Oops: there was an issue communicating with our servers');
      session.completeMerchantValidation({});
      return;
    }
  };

  /**
   * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/providing_merchant_validation
   * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/requesting_an_apple_pay_payment_session
   *
   * @param event
   * @param session
   */
  const onValidateMerchant: OnValidateMerchant = async (event, session) => {
    try {
      // request a new merchant session
      const response: OnValidateMerchantResponse = (
        await axios.post(`${backendEndpoint}/apple-pay/on-validate-merchant`, {
          validationURL: event.validationURL,
          order,
          totalCents,
          uid: getUid(),
          cid,
          campaign
        })
      ).data;

      const { merchantSession, error } = response;

      if (error.userId) {
        setGeneralError('please check the username is correct and try again');
        session.completeMerchantValidation({});
        return;
      } else if (error.private) {
        setGeneralError('please keep your profile public so people can follow you');
        session.completeMerchantValidation({});
        return;
      } else {
        setGeneralError('');
      }

      session.completeMerchantValidation(merchantSession);
    } catch (e) {
      setGeneralError('Oops: there was an issue communicating with our servers');
      session.completeMerchantValidation({});
      return;
    }
  };

  const { applePayClickHandler } = useApplePay({
    paymentRequest,
    onPaymentAuthorized,
    onValidateMerchant,
    onCancel: (event, session) => {
      if (!isProd) console.log('cancel', event, session);
    },
    // make sure this is exhaustive
    dependencies: [cents, item, platform, orderType, quantity, interval, username, posts, totalCents, campaign, cid]
  });

  return (
    <ApplePayButton
      isTesting={isTesting}
      buttonstyle="black"
      locale="en-US"
      type="pay"
      onClick={applePayClickHandler}
    />
  );
};

export default ApplePay;
