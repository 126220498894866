import gtag from 'ga-gtag';
import ReactPixel from 'react-snapchat-pixel';
import { isProd } from '~constants';

export enum Page {
  home,
  thanks,
  account,
  contact,
  refund,
  privacy,
  terms,
  mission
}

export function trackPageView(page: Page) {
  if (!isProd) return;

  switch (page) {
    case Page.home:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/',
        page_path: '/',
        page_title: 'Home'
      });
      gtag('event', 'page_view_home');
      ReactPixel.pageView(); // For tracking page view
    case Page.thanks:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/thanks',
        page_path: '/thanks',
        page_title: 'Thanks'
      });
      gtag('event', 'page_view_thanks');
      break;
    case Page.contact:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/contact',
        page_path: '/legal/contact',
        page_title: 'Contact Us'
      });

      gtag('event', 'page_view_contact');
      break;
    case Page.privacy:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/legal/privacy',
        page_path: '/legal/privacy',
        page_title: 'Privacy Policy'
      });

      gtag('event', 'page_view_privacy');
      break;
    case Page.refund:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/legal/refund',
        page_path: '/legal/refund',
        page_title: 'Refund Policy'
      });

      gtag('event', 'page_view_refund');
      break;
    case Page.terms:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/legal/terms',
        page_path: '/legal/terms',
        page_title: 'Terms and Conditions'
      });

      gtag('event', 'page_view_terms');
      break;
    case Page.account:
      gtag('event', 'page_view', {
        page_location: 'https://yofame.com/account/:hotlink',
        page_path: '/account/:hotlink',
        page_title: 'Account'
      });

      gtag('event', 'page_view_account');
      break;
  }
}
