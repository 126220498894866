import { PaymentRequest } from '@stripe/stripe-js';
import axios, { AxiosResponse } from 'axios';
import { createPaymentIntent } from '~api/createPaymentIntent';
import { getPosts } from '~api/getPosts';
import { getProfile } from '~api/getProfile';
import { infoCollect } from '~api/infoCollect';
import { initiatePurchase } from '~api/initiatePurchase';
import { sendSignInLink } from '~api/sendSignInLink';
import { backendEndpoint } from '~constants';
import { ClientAccountInfo } from '~models/ClientAccountInfo';
import { Config, ConfigOptionItem } from '~models/Config';
import { DispatchI } from '~models/Dispatch';
import { Post } from '~models/Post';
import { PriceInterval } from '~models/PriceInterval';
import { UsernamePosts } from '~models/UsernamePosts';

interface InitializeDispatch {
  config: {
    setCid: (cid: string) => void;
    setFlow: (flow: string) => void; // "a" for original or "b" for new step flow
    setRemoteConfig: (remote: Config) => void;
  };
  order: {
    setInterval: (interval: PriceInterval) => void;
    setItem: (item: ConfigOptionItem) => void;
    setCents: (cents: number) => void;
    setUsername: (username: string) => void;
    setPhone: (phone: string) => void;
    setPosts: (posts: Post[]) => void;
    error: {
      setCentsError: (error: string) => void;
      setGeneralError: (error: string) => void;
      setUsernameError: (error: string) => void;
      setPhoneError: (error: string) => void;
    };
    cookie: {
      setCentsCookieKey: (key: string) => void;
      setUsernameCookieKey: (key: string) => void;
      setPhoneCookieKey: (key: string) => void;
    };
  };
  setLoadedPosts: (usernamePosts: UsernamePosts[]) => void;
  track: {
    setCampaign: (campaign: string) => void;
  };
  backend: {
    setInFlight: (inFlight: boolean) => void;
  };
  payment: {
    setPaymentRequest: (request: PaymentRequest) => void;
  };
}

function initializeDispatch(params: InitializeDispatch): DispatchI {
  const {
    config: { setCid, setFlow, setRemoteConfig },
    order: {
      setInterval,
      setItem,
      setCents,
      setUsername,
      setPhone,
      setPosts,
      error: { setCentsError, setGeneralError, setUsernameError, setPhoneError },
      cookie: { setCentsCookieKey, setUsernameCookieKey, setPhoneCookieKey }
    },
    setLoadedPosts,
    backend: { setInFlight },
    track: { setCampaign },
    payment: { setPaymentRequest }
  } = params;

  const hotLinkLogin = async (hotlink: string): Promise<void> => {
    const result: AxiosResponse<ClientAccountInfo> = await axios.post(`${backendEndpoint}/api/hotlink-sign-in`, {
      hotlink
    });

    // updateState((draft) => {
    //   draft.hotlink = hotlink;
    //   draft.clientAccountInfo = result.data;
    // });
  };

  return {
    config: {
      setCid,
      setFlow,
      setRemoteConfig
    },
    order: {
      setInterval,
      setItem,
      setCents,
      setUsername,
      setPhone,
      setPosts,
      error: {
        setCentsError,
        setGeneralError,
        setUsernameError,
        setPhoneError
      },
      cookie: {
        setCentsCookieKey,
        setUsernameCookieKey,
        setPhoneCookieKey
      }
    },
    backend: {
      getPosts,
      getProfile,
      infoCollect,
      setInFlight
    },
    track: {
      setCampaign
    },
    setLoadedPosts,
    payment: {
      createPaymentIntent,
      initiatePurchase,
      setPaymentRequest
    },
    account: {
      sendSignInLink,
      hotLinkLogin
    }
  };
}

export default initializeDispatch;
