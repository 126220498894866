import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { PriceInterval } from '~models/PriceInterval';
import { SubTitle } from '~pages/-feature/typography/Typography';
import { formatNumber } from '~utils/format';

const FooterWrapper = styled.footer`
  max-width: 700px;
  margin: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

function formatCentsToDollars(cents: number): string {
  const dollars = cents / 100;
  return `${dollars.toFixed(2)}`;
}

interface Props {
  justMail?: boolean;
}

export const Bottom = (props: Props) => {
  const [state, dispatch] = useContext();

  const {
    order: { cents, interval, posts, item }
  } = state;

  const { justMail } = props;

  const content = useMemo((): JSX.Element => {
    if (justMail)
      return (
        <span className="pricing-label" style={{ textAlign: 'center' }}>
          Enterprise Postcards LLC
          <br />8 The Green, STE D<br />
          Dover, DE 19901
        </span>
      );

    const dollars = formatCentsToDollars(cents);

    switch (interval) {
      case PriceInterval.month:
        return <span className="pricing-label">${dollars}/month. Cancel Anytime!</span>;
      case PriceInterval.week:
        return <span className="pricing-label">${dollars}/week. Cancel Anytime!</span>;
      case PriceInterval.auto:
      case PriceInterval.once:
        switch (item?.orderType) {
          case OrderType.Follow:
            return <span className="pricing-label">${dollars}</span>;
          default:
            return (
              <div style={{ textAlign: 'center' }}>
                {item?.platform === Platform.Tok && item?.orderType === OrderType.View && (
                  <SubTitle fontSize={'12px'} margin={'-8px 0 0 0'}>
                    {formatNumber(item?.quantity)} Views +{' '}
                    {item?.quantity <= 2500 ? formatNumber(100) : formatNumber(500)} FREE Likes / per post
                  </SubTitle>
                )}
                <span className="pricing-label">
                  ${dollars} × {posts.length} posts = $<u>{(parseFloat(dollars) * posts.length).toFixed(2)}</u>
                </span>
              </div>
            );
        }
    }
  }, [interval, cents, posts, justMail]);

  return (
    <FooterWrapper>
      <div className="yf-flex cf-footer-layout cf-footer--mobile" style={{ bottom: 0 }}>
        <div className="yf-layout-item yf-flex summary-panel--footer" style={{ width: '100%' }}>
          <div className="footer-action-block">
            <div className="modal-trigger-container">
              <button className="modal-trigger yf-btn yf-btn--link" type="button">
                <div className="yf-flex-item summary-options">
                  <div className="yf-flex-item yf-flex--col_1of5" style={{ minWidth: 0 }}>
                    <div className="yf-flex yf-text--body yf-text--500 yf-text_color--10 summary-panel--item">
                      {content}
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};
