import { PaymentRequest } from '@stripe/stripe-js';
import React, { createContext, useState } from 'react';
import initializeDispatch from '~contexts/initializeDispatch';
import initializeState from '~contexts/initializeState';
import { Config, ConfigOptionItem } from '~models/Config';
import { Dispatch } from '~models/Dispatch';
import { Post } from '~models/Post';
import { PriceInterval } from '~models/PriceInterval';
import { State } from '~models/State';
import { UsernamePosts } from '~models/UsernamePosts';
import { getCookie } from '~utils/cookie';

export const StateContext = createContext<State>(null);
export const DispatchContext = createContext<Dispatch>(null);

export const Provider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const [cid, setCid] = useState(getCookie('cid') || '');
  const [flow, setFlow] = useState(getCookie('flow') || 'a');
  const [remoteConfig, setRemoteConfig] = useState(null as unknown as Config);

  const [campaign, setCampaign] = useState(
    urlParams.get('c') ?? urlParams.get('ref') ?? getCookie('campaign') ?? 'organic'
  );

  const [interval, setInterval] = useState(PriceInterval.once);

  const [item, setItem] = useState(null as unknown as ConfigOptionItem);

  const [centsCookieKey, setCentsCookieKey] = useState('cents');
  const [usernameCookieKey, setUsernameCookieKey] = useState('username');
  const [phoneCookieKey, setPhoneCookieKey] = useState('phone');

  const [cents, setCents] = useState(parseInt(getCookie(centsCookieKey) || '0'));
  const [username, setUsername] = useState(getCookie(usernameCookieKey) || '');
  const [phone, setPhone] = useState(getCookie(phoneCookieKey) || '');
  const [posts, setPosts] = useState([] as Post[]);

  const [centsError, setCentsError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [loadedPosts, setLoadedPosts] = useState([] as UsernamePosts[]);

  const [inFlight, setInFlight] = useState(false);

  const [paymentRequest, setPaymentRequest] = useState(null as unknown as PaymentRequest);

  const state = initializeState({
    config: {
      cid,
      flow,
      remoteConfig
    },
    track: {
      campaign
    },
    order: {
      interval,
      item,
      cents,
      username,
      phone,
      posts,
      error: { centsError, generalError, usernameError, phoneError },
      cookie: { centsCookieKey, usernameCookieKey, phoneCookieKey }
    },
    loadedPosts,
    backend: { inFlight },
    payment: { paymentRequest }
  });

  const dispatch = initializeDispatch({
    config: {
      setCid,
      setFlow,
      setRemoteConfig
    },
    order: {
      setInterval,
      setItem,
      setCents,
      setUsername,
      setPhone,
      setPosts,
      error: { setCentsError, setGeneralError, setUsernameError, setPhoneError },
      cookie: { setCentsCookieKey, setUsernameCookieKey, setPhoneCookieKey }
    },
    setLoadedPosts,
    track: {
      setCampaign
    },
    backend: { setInFlight },
    payment: { setPaymentRequest }
  });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};
