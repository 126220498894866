import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import styled from 'styled-components';
import logo from '~assets/img/yofame-text-tiny.png';
import { Link } from '~pages/-feature/typography/Link';
import { Menu } from '~pages/-feature/menu/Menu';

const Wrapper = styled.header`
  max-width: 650px;
  margin: auto;

  position: sticky;
  top: 0;
  z-index: 10;
  padding: var(--yf-size--2x) var(--yf-size--3x);
  background: white;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 13px;
  margin-top: 6px;
`;

interface Props {
  nav?: 'home'; // for legal sub-pages
}

export const Top = ({ nav }: Props) => {
  return (
    <Wrapper>
      {nav === 'home' && (
        <Link to="/">
          <HomeIcon style={{ fontSize: 25 }} />
        </Link>
      )}
      {!nav && (
        <Link to="/">
          <Logo src={logo} alt="logo" />
        </Link>
      )}
      <Menu />
    </Wrapper>
  );
};
