import { useContext } from 'react';
import { StateContext } from '~contexts/main';
import { StateI } from '~models/State';

export const useStateContext = (): StateI => {
  const state = useContext(StateContext);

  if (!state) {
    throw new Error('Uh oh, where is my state?');
  }

  return state;
};
