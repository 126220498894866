import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { useContext } from '~hooks/useContext/useContext';
import { useTotalCents } from '~hooks/useTotalCents';
import { ConfigOptionItem } from '~models/Config';
import { OrderType } from '~models/OrderType';
import Button from '~pages/main/Button';
import { handleTapPurchase } from '~pages/main/payment/handleTapPurchase';

const Order = () => {
  const [state, dispatch] = useContext();
  const [loading, setLoading] = useState(false);
  const { logEvent } = useAmplitude();

  const [actionText, setActionText] = useState('Boost');

  const {
    order: { item }
  } = state;

  const {
    config: { cid },
    order: {
      interval,
      item: { platform, orderType },
      posts,
      cents,
      username,
      phone,
      error: { centsError, generalError }
    },
    track: { campaign },
    payment: { paymentRequest }
  } = state;

  const {
    backend: { getProfile },
    order: {
      error: { setCentsError, setGeneralError, setUsernameError }
    },
    payment: { initiatePurchase }
  } = dispatch;

  const optionItems: ConfigOptionItem[] = useMemo(() => {
    if (posts && orderType !== OrderType.Follow) return posts.map(() => item);
    return [item];
  }, [posts, item, orderType]);

  const totalCents: number = useTotalCents(orderType, posts, cents);

  useEffect(() => {
    if (centsError !== '' && totalCents > 0) setCentsError('');
  }, [centsError, totalCents]);

  // reset text when username changes
  useEffect(() => {
    setActionText('Boost');
    setGeneralError('');
  }, [username]);

  const tapPurchase = useCallback(async () => {
    await handleTapPurchase({
      platform,
      username,
      phone,
      optionItems,
      posts,
      interval,
      orderType,
      cents: totalCents,
      campaign,
      cid,
      loading,
      paymentRequest,
      setLoading,
      setUsernameError,
      setCentsError,
      getProfile,
      initiatePurchase,
      setActionText,
      setGeneralError,
      logEvent
    });
  }, [loading, item, platform, optionItems, interval, username, posts, totalCents, campaign, cid, paymentRequest]);

  return <Button onClickHandler={tapPurchase} processing={loading} actionText={actionText} />;
};

export default Order;

// const navigate = useNavigate();
// const stripe = useStripe();
// const goThanks = useCallback(() => navigate('/thanks'), [navigate]);
// useEffect(() => createPaymentRequest({ stripe, platform, cents, setPaymentRequest }), [stripe, platform, cents]);
// useEffect(() => {
//   handlePaymentMethodEvent({
//     optionItems: [item],
//     platform,
//     interval,
//     username,
//     cid: state.config.cid,
//     campaign: state.track.campaign,
//     createPaymentIntent,
//     goThanks,
//     paymentRequest,
//     stripe
//   });
// }, []);
