import { OrderType } from '~models/OrderType';
import { HandleTapPurchase } from '~models/payment/HandleTapPurchase';
import { checkUsernameExistsAndPublic } from '~pages/main/payment/checkUsernameExistsAndPublic';
import { extractLineItems } from '~pages/main/payment/extractLineItems';
import { trackBeginCheckout } from '~pages/main/payment/trackBeginCheckout';
import { getUid } from '~utils/cookie';
import { isValidUsernameFormat } from '~utils/isValidUsernameFormat';

export async function handleTapPurchase(params: HandleTapPurchase): Promise<void> {
  const {
    platform,
    username,
    phone,
    optionItems,
    posts,
    cents,
    interval,
    orderType,
    campaign,
    cid,
    loading,
    setLoading,
    setUsernameError,
    setCentsError,
    getProfile,
    initiatePurchase,
    setGeneralError,
    setActionText,
    logEvent
  } = params;

  if (loading) return;

  logEvent('tap_purchase');

  if (cents === 0) {
    logEvent('tap_purchase_error', { error: 'price is 0', orderType });
    if (posts && orderType !== OrderType.Follow) {
      setCentsError('Please select at least one post.');
    } else {
      setCentsError('Total price must be more than $0');
    }
    return;
  }

  const isValid = isValidUsernameFormat(username, setUsernameError);
  if (!isValid) {
    logEvent('tap_purchase_error', { error: 'username format invalid' });
    return;
  }

  setLoading(true);

  if (orderType === OrderType.Follow) {
    const isValid = await checkUsernameExistsAndPublic({
      platform,
      username,
      phone,
      getProfile,
      setGeneralError
    });
    if (!isValid) {
      setLoading(false);
      setActionText('Try Again');
      return;
    }
  }

  trackBeginCheckout(params);

  await initiatePurchase({
    cid,
    uid: getUid(),
    platform,
    username,
    campaign,
    posts,
    lineItems: extractLineItems({ optionItems, interval })
  });
  setLoading(false);

  // if (paymentRequest) { await paymentRequest.show() } else { initiatePurchase... }
}
