import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatNumber } from '~utils/format';

const Wrapper = styled.div`
  flex-grow: 1;
  margin: 10px 6px;
`;

const OptionGroup = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  text-align: center;
`;

export const OptionInput = styled.input`
  :active,
  :focus {
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
  }

  :not(:focus):not(:active) {
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
  }
`;

const FormItem = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: var(--yf-form-item-margin_bottom);
`;

const OptionLabel = styled.label`
  flex-grow: 1;
  border-radius: var(--yf-border-radius--pill);
  width: 100%;
  padding: 12px var(--yf-size--3x);
  cursor: pointer;
  transition: box-shadow 0.33s cubic-bezier(0.5, 0, 0, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.2;
  background-color: transparent;
`;

const LabelTitle = styled.span`
  display: flex;
  justify-content: center;
  color: var(--yf-color--black);
  font-variant-numeric: lining-nums;
  white-space: nowrap;
  margin: 0;
`;

const TextLeft = styled.p`
  outline: none;
`;

export interface RadioProps {
  radioGroupId: string;
  value: number;
  selectedValue: number;
  onSelect: (value: number) => void;
}

export const Radio = (props: RadioProps) => {
  const { radioGroupId, value, selectedValue, onSelect } = props;

  const [selected, setSelected] = useState(false);

  const onChange = useCallback(() => onSelect(value), [onSelect, value]);

  useEffect(() => setSelected(value === selectedValue), [value, selectedValue]);

  return (
    <Wrapper data-id={radioGroupId + '-' + value} data-id-selected={selected} aria-label={value}>
      <FormItem>
        <OptionGroup>
          <OptionInput
            type="radio"
            name={radioGroupId}
            id={radioGroupId + '-' + value}
            value={value}
            onChange={onChange}
            checked={selected}
          />
          <OptionLabel
            htmlFor={radioGroupId + '-' + value}
            selected={selected}
            style={
              selected
                ? {
                    boxShadow: '0 0 0 3px var(--yf-color--blue30)',
                    fontFamily: 'var(--yf-font-combined--medium)'
                  }
                : { boxShadow: '0 0 0 1px var(--yf-color--grey40)' }
            }
          >
            <LabelTitle selected={selected}>
              <TextLeft>{formatNumber(value)}</TextLeft>
            </LabelTitle>
          </OptionLabel>
        </OptionGroup>
      </FormItem>
    </Wrapper>
  );
};
