import React, { createElement, FC } from 'react';
import styled from 'styled-components';
import { ApplePayButtonProps } from 'types/apple-pay';
import { useScript } from '~hooks/useScript';

interface Props extends ApplePayButtonProps {
  isTesting: boolean; // forces button to display
}

/**
 * Source: https://github.com/midoghranek/use-apple-pay/blob/master/example/index.tsx
 */
const ApplePayButton: FC<Props> = (props: Props) => {
  useScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js');

  return (
    <ApplePayButtonStyled>
      {createElement<ApplePayButtonProps, HTMLElement>('apple-pay-button', {
        buttonstyle: props.buttonstyle || 'black',
        type: props.type || 'pay',
        locale: props.locale || 'en-US'
      })}
    </ApplePayButtonStyled>
  );
};

export default ApplePayButton;

const ApplePayButtonStyled = styled.div`
  apple-pay-button {
    --apple-pay-button-width: 98%;
    --apple-pay-button-height: 45px;
    --apple-pay-button-border-radius: 7px;
    --apple-pay-button-padding: 7px 7px;
    --apple-pay-button-box-sizing: border-box;
    margin: 15px auto 15px auto;
  }
`;
