import React from 'react';
import styled from 'styled-components';
import { Loading } from '~pages/-feature/Loading';

const ButtonWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface ButtonProps {
  onClickHandler: () => {};
  processing: boolean;
  actionText: string;
}

const Button = ({ onClickHandler, processing, actionText }: ButtonProps) => {
  return (
    <ButtonWrapper className="yf-flex yf-flex--justify-center">
      <button
        type="button"
        className="yf-link action-trigger--link text-loader--content yf-btn yf-btn--blue yf-btn--full continue-to-payment-btn"
        data-id="btn-navigation"
        data-subtype="btn-navigation--payment"
        onClick={onClickHandler}
      >
        <span>{processing ? 'Please Wait' : actionText}</span>
        {processing && <Loading />}
      </button>
    </ButtonWrapper>
  );
};

export default Button;
