import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CommentIcon from '@material-ui/icons/Comment';
import LikesIcon from '@material-ui/icons/Favorite';
// import FlagIcon from '@material-ui/icons/Flag';
import FollowIcon from '@material-ui/icons/PersonAdd';
import ViewIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';

const SectionTitle = styled.div`
  font-weight: 500;
  font-family: var(--yf-font-combined--medium);
  padding: 0 0 10px 20px;
`;

interface MenuSectionProps {
  platform: Platform;
  orderTypes: OrderType[];
}

export const MenuSection = (props: MenuSectionProps) => {
  const [state] = useContext();
  // const location = useLocation();

  const {
    order: {
      item: { platform: selectedPlatform, orderType }
    }
  } = state;

  const { platform, orderTypes } = props;

  return (
    <List>
      <SectionTitle>{platform}</SectionTitle>
      {orderTypes.map((type) => {
        let isSelected = platform === selectedPlatform && type === orderType;
        // if (location.pathname === '/company/mission') {
        //   isSelected = platform === Platform.Company && type === OrderType.Mission;
        // }

        return (
          <Link to={getLink(type, platform)} key={getText(type, platform)}>
            <ListItem style={isSelected ? { background: '#f4f4f4' } : {}} button>
              <ListItemIcon style={{ minWidth: 35, paddingLeft: 5 }}>{getIcon(type)}</ListItemIcon>
              <ListItemText primary={getText(type, platform)} />
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export function getLink(type: OrderType, platform: Platform): string {
  // if (platform === Platform.Company) {
  //   return `/${platform.toLowerCase()}/${type.toLowerCase()}`;
  // } else {
  return `/${platform.toLowerCase()}/${type.toLowerCase()}s`;
  // }
}

function getIcon(type: OrderType) {
  switch (type) {
    case OrderType.Follow:
      return <FollowIcon />;
    case OrderType.Like:
      return <LikesIcon />;
    case OrderType.Comment:
      return <CommentIcon />;
    case OrderType.View:
      return <ViewIcon />;
    // case OrderType.Mission:
    //   return <FlagIcon />;
  }
}

function getText(type: OrderType, platform: Platform) {
  switch (type) {
    case OrderType.Follow:
      return 'Followers';
    case OrderType.Like:
      return 'Likes';
    case OrderType.Comment:
      return 'Comments';
    case OrderType.View:
      return 'Views & Likes';
    // case OrderType.Mission:
    //   return 'Mission';
  }
}
