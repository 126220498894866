import React from 'react';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { Link } from '~pages/-feature/typography/Link';

export const LegalBullet = () => {
  return (
    <li className="yf-list-item text-loader--list_element" key={'legal'}>
      <span className="text-loader--content text-loader--list_element--text">
        By using our service you agree to the&nbsp;
        <Link style={linkStyle} to={'/legal/terms'}>
          Terms
        </Link>
        ,&nbsp;
        <Link style={linkStyle} to={'/legal/privacy'}>
          Privacy
        </Link>
        ,&nbsp;and&nbsp;
        <Link style={linkStyle} to={'/legal/refund'}>
          Refund
        </Link>
        &nbsp;policies. Please feel free to{' '}
        <Link style={linkStyle} to={'/contact'}>
          Contact Us
        </Link>{' '}
        for direct support.
      </span>
    </li>
  );
};
