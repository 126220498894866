import React from 'react';
import styled from 'styled-components';
import { SubTitle } from '~pages/-feature/typography/Typography';

const TitleWrapper = styled.div`
  padding-top: var(--yf-size--1x);
  padding-bottom: var(--yf-size--1x);
`;

const Title = styled.h2`
  outline: none;
  text-align: center;
`;

const Price = styled.p`
  text-align: center;
  --yf-text--color: var(--yf-color--black);
  color: var(--yf-text--color);
  font-weight: 500 !important;
  font-family: var(--yf-font-combined--medium) !important;
  padding-top: 5px;
`;

interface Props {
  title: string;
  subtitle?: string;
  price?: string;
}

export const SectionTitle = (props: Props) => {
  const { title, subtitle, price } = props;

  return (
    <TitleWrapper>
      <Title>{title}</Title>

      {subtitle && (
        <SubTitle fontSize={'16px'} margin={'10px 0 0 0'}>
          {subtitle}
        </SubTitle>
      )}

      {price && <Price>${price}</Price>}
    </TitleWrapper>
  );
};
