import amplitude from 'amplitude-js';
import React, { useMemo, useState } from 'react';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import '~assets/styles/index.scss';
import { amplitudeKey, isProd } from '~constants';
import { Provider } from '~contexts';
import { useContext } from '~hooks/useContext/useContext';
import useSetup from '~hooks/useSetup';
import { Loading } from '~pages/-feature/Loading';
import { AccountPage } from '~pages/account/AccountPage';
import { SignInPage } from '~pages/account/SignInPage';
import { ErrorPage } from '~pages/error/ErrorPage';
import { PageContact } from '~pages/legal/PageContact';
import { PagePrivacy } from '~pages/legal/PagePrivacy';
import { PageRefund } from '~pages/legal/PageRefund';
import { PageTerms } from '~pages/legal/PageTerms';
import { MainPage } from '~pages/main/MainPage';
import { ThanksPage } from '~pages/thanks/ThanksPage';
import { theme } from '~theme';
import { getUid } from '~utils/cookie';

export default () => {
  return (
    <Provider>
      <AmplitudeRoot />
    </Provider>
  );
};

const AmplitudeRoot = () => {
  const [state] = useContext();

  return (
    <>
      {isProd ? (
        <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={amplitudeKey} userId={getUid()}>
          <Amplitude
            eventProperties={{
              isProd,
              flow: state.config.flow,
              cid: state.config.cid,
              campaign: state.track.campaign
            }}
          >
            <ThemedRouter />
          </Amplitude>
        </AmplitudeProvider>
      ) : (
        <ThemedRouter />
      )}
    </>
  );
};

const ThemedRouter = () => {
  const [state] = useContext();
  const [flowTheme, setFlowTheme] = useState(theme.a);

  useMemo(() => {
    switch (state.config.flow) {
      case 'a':
        setFlowTheme(theme.a);
        break;
      case 'b':
        setFlowTheme(theme.b);
    }
  }, [state.config.flow]);

  return (
    <ThemeProvider theme={flowTheme}>
      <Routing />
    </ThemeProvider>
  );
};

const Routing = () => {
  const [loading] = useSetup();

  return (
    <Routes>
      <Route path={`/sign-in`} element={<SignInPage />} />
      <Route path={`/account/:hotlink`} element={<AccountPage />} />

      <Route path={`/legal/terms`} element={<PageTerms />} />
      <Route path={`/legal/privacy`} element={<PagePrivacy />} />
      <Route path={`/legal/refund`} element={<PageRefund />} />
      <Route path={`/contact`} element={<PageContact />} />
      {/*<Route path={`/company/mission`} element={<PageMission />} />*/}
      <Route path={'/error'} element={<ErrorPage errorCode={'404'} />} />

      <Route path={`/thanks/:platform/:orderType`} element={loading ? <Loading /> : <ThanksPage />} />
      <Route path={`/thanks`} element={loading ? <Loading /> : <ThanksPage />} />

      <Route path="/:platform/:orderType" element={loading ? <Loading /> : <MainPage />} />

      <Route path={`/`} element={loading ? <Loading /> : <MainPage />} />

      <Route path="*" element={<ErrorPage errorCode={'404'} />} />
    </Routes>
  );
};
