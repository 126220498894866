import { GetProfileRequest } from '~models/api-request/GetProfileRequest';
import { GetProfileResponse } from '~models/api/GetProfileResponse';
import { Platform } from '~models/Platform';
import { getUid } from '~utils/cookie';

interface CheckUsernameExistsAndPublic {
  platform: Platform;
  username: string;
  phone: string;
  getProfile: (params: GetProfileRequest) => Promise<GetProfileResponse>;
  setGeneralError: (error: string) => void;
}

export async function checkUsernameExistsAndPublic(params: CheckUsernameExistsAndPublic): Promise<boolean> {
  const { platform, username, phone, getProfile, setGeneralError } = params;

  try {
    const getProfileResponse: GetProfileResponse = await getProfile({
      uid: getUid(),
      platform,
      username,
      phone
    });

    if (getProfileResponse.error.userId) {
      setGeneralError('please check the username is correct and try again');
      return false;
    } else if (getProfileResponse.error.private) {
      setGeneralError('please keep your profile public so people can follow you');
      return false;
    } else {
      setGeneralError('');
      return true;
    }
  } catch (e) {
    setGeneralError('Oops: there was an issue communicating with our servers');
    return false;
  }
}
