import { Divider } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { Post } from '~models/Post';
import { SubTitle } from '~pages/-feature/typography/Typography';
import OrderWrapper from '~pages/main/order/OrderWrapper';
import LoadPosts from '~pages/main/posts/LoadPosts';
import PostsWrapper from '~pages/main/posts/PostsWrapper';

const SelectPosts = () => {
  const [state, dispatch] = useContext();

  const {
    order: {
      username,
      posts,
      item: { platform }
    },
    loadedPosts
  } = state;

  const {
    order: { setPosts }
  } = dispatch;

  useEffect(() => {
    if (posts.length > 0 && posts[0].username !== username) {
      // clear ordered posts since username changed (maybe in another tab)
      console.log('clearing ordered posts since username changed');
      setPosts([]);
    }
  }, [username, posts]);

  const postList: Post[] | undefined = useMemo(() => {
    const filtered = loadedPosts?.filter((up) => up.username === username && up.platform === platform) ?? [];
    return filtered.length > 0 ? filtered[0].posts : undefined;
  }, [loadedPosts, username, platform]);

  return (
    <>
      <LoadPosts />

      {postList && postList.length > 0 && (
        <>
          <Divider style={{ height: 2, margin: '15px 0' }} />

          <SubTitle fontSize={'16px'} margin={'0 0 15px 0'}>
            Choose which posts to boost:
          </SubTitle>

          <PostsWrapper postList={postList} />

          {/*<LoadMorePosts cursorMore={usernamePosts?.cursorMore} />*/}

          <OrderWrapper />
        </>
      )}
    </>
  );
};

export default SelectPosts;
