import gtag from 'ga-gtag';
import React, { useEffect, useState } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { supportEmail, supportPhone } from '~constants';
import { useContext } from '~hooks/useContext/useContext';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { Toggler } from '~pages/-feature/form/Toggler';
import { Loading } from '~pages/-feature/Loading';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper, Title } from '~pages/-feature/typography/Typography';
import { Page, trackPageView } from '~utils/tracking';

export const Item = styled.div`
  padding-bottom: 10px;
`;

interface Props {}

export const AccountPage = (props: Props) => {
  const [state, dispatch] = useContext();

  const { logEvent } = useAmplitude();

  const { hotlink } = useParams<{ hotlink: string }>();

  const clientAccountInfo = state.account?.clientAccountInfo;

  useEffect(() => {
    if (!clientAccountInfo && hotlink) {
      dispatch.account.hotLinkLogin(hotlink);
    }
  }, [clientAccountInfo, dispatch, hotlink]);

  useEffect(() => trackPageView(Page.account), []);

  const [tab, setTab] = useState(0);

  const [showCancelText, setShowCancelText] = useState(false);

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LogOnMount eventType="page:account" />
        <Top nav={'home'} />

        {!clientAccountInfo && <Loading />}

        <Content style={{ textAlign: 'left', color: 'var(--yf-text--color)' }}>
          <Title style={{ textAlign: 'left', paddingBottom: 10 }}>Account</Title>
          <Toggler leftText={'Profile'} rightText={'Orders'} onSelected={setTab} initialSelected={tab} />
          {clientAccountInfo && (
            <>
              {tab === 0 && (
                <>
                  {/*<Item>Name: {clientAccountInfo?.name}</Item>*/}
                  <Item>Your Phone: {clientAccountInfo?.phone}</Item>
                  {/*<Item>Our Email: {clientAccountInfo?.email}</Item>*/}
                </>
              )}
              {tab === 1 && (
                <>
                  {clientAccountInfo.grams.map((p, i) => {
                    return (
                      <div key={i}>
                        <Item>Username: {p.platformUsername}</Item>
                        <Item>Subscription Id: {p.stripeSubId}</Item>
                        {showCancelText && (
                          <Item>
                            Please text us at&nbsp;
                            <a style={linkStyle} href={`tel:${supportPhone}`}>
                              {supportPhone}
                            </a>
                            &nbsp;or email us at&nbsp;
                            <a style={linkStyle} href={`mailto:${supportEmail}`}>
                              {supportEmail}
                            </a>
                            &nbsp;to cancel. We promise to cancel your subscription and refund any new charges. Please
                            allow us up to 24-hours to text back.
                          </Item>
                        )}
                        <button
                          /* tslint:disable-next-line:jsx-no-lambda */
                          onClick={() => {
                            gtag('event', 'tap_cancel_sub');
                            logEvent('tap_cancel_subscription');
                            setShowCancelText(true);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
