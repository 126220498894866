import React, { useCallback } from 'react';
import styled from 'styled-components';
import instagramIcon from '~assets/img/instagram-icon.png';
import tokIcon from '~assets/img/tok-icon-vibrant.png';
import { ErrorText, FormItemLabel } from '~pages/-feature/typography/Typography';
import { setCookie } from '~utils/cookie';

const FormItem = styled.div`
  text-align: left; // JM
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: ${(props) => (props.theme.flow === 'b' ? '0' : 'var(--yf-form-item-margin_bottom)')};
`;

const TextInputWrapper = styled.label`
  width: 100%;
  display: inline-block;
  position: relative;
`;

const TextInput = styled.input`
  height: ${(props) => (props.theme.flow === 'b' ? '55px' : 'var(--yf-input-height)')};
  font-size: ${(props) => (props.theme.flow === 'b' ? '17px' : 'var(--yf-font-size--30)')};
  border-radius: ${(props) => (props.theme.flow === 'b' ? '28px' : 'var(--yf-border-radius--forms)')};
  background-color: ${(props) => (props.theme.flow === 'b' ? 'white' : 'var(--yf-color--grey70)')};
  border: ${(props) => (props.theme.flow === 'b' ? '1px solid black' : '1px solid transparent')};
  font-family: var(--yf-font-combined--medium);
  line-height: var(--yf-line-height--30);
  font-weight: ${(props) => (props.theme.flow === 'b' ? 600 : 500)};
  color: var(--yf-color--grey10);
  padding: var(--yf-input-padding_top) var(--yf-form-item-horizontal_spacer) var(--yf-input-padding_bottom);
  transition: border 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  width: 100%;

  background-image: ${(props) =>
    props.igIcon === true ? `url(${instagramIcon})` : props.tokIcon === true ? `url(${tokIcon})` : ''};

  background-size: ${(props) => (props.theme.flow === 'a' ? '20px 20px' : '25px 25px')};
  background-repeat: no-repeat;
  background-position: ${(props) => (props.theme.flow === 'a' ? '15px 9px' : '15px 13px')};

  padding-left: ${(props) =>
    props.igIcon === true ? '50px' : props.tokIcon === true ? '47px' : 'var(--yf-form-item-horizontal_spacer)'};

  :active,
  :focus {
    border: ${(props) => (props.theme.flow === 'b' ? '1px solid black' : '1px solid var(--yf-color--grey50)')};
    outline: none;
  }

  :disabled:active,
  :disabled:focus {
    border-color: transparent;
  }

  ::placeholder {
    color: var(--yf-color--grey40);
    opacity: 0.5;
  }

  ::-moz-placeholder {
    color: var(--yf-color--grey40);
    opacity: 0.5;
  }

  :-ms-input-placeholder {
    color: var(--yf-color--grey40);
    opacity: 0.5;
  }
`;

interface Props {
  cookieKey: string;
  labelText: string;
  inputId: string;
  maxLength: number;
  style?: {};
  value: string;
  igIcon?: boolean;
  tokIcon?: boolean;
  errorText: string;
  setError: (string) => void;
  onChange: (string) => void;
}

const TextInputComponent = (props: Props) => {
  const {
    cookieKey,
    labelText,
    inputId,
    maxLength,
    style,
    value,
    igIcon,
    tokIcon,
    errorText,
    setError,
    onChange: onChangeCb
  } = props;

  const onChange = useCallback(
    (e) => {
      const val = e.target.value;
      onChangeCb(val);
      setCookie(cookieKey, val);
      // if (!isProd) console.log(val);

      if (errorText.length > 0) {
        setError('');
      }
    },
    [onChangeCb, setError, cookieKey, errorText.length]
  );

  return (
    <FormItem style={style}>
      <FormItemLabel htmlFor={inputId}>{labelText}</FormItemLabel>
      <TextInputWrapper>
        <TextInput
          id={inputId}
          maxLength={maxLength}
          onChange={onChange}
          value={value}
          igIcon={igIcon}
          tokIcon={tokIcon}
          style={errorText ? { borderColor: 'var(--yf-color--red20)' } : {}}
        />
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </TextInputWrapper>
    </FormItem>
  );
};

export default TextInputComponent;
