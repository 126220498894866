import { useContext } from 'react';
import { DispatchContext } from '~contexts/main';
import { DispatchI } from '~models/Dispatch';

export const useDispatchContext = (): DispatchI => {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('Uh oh, where is my dispatch?');
  }

  return dispatch;
};
