import React from 'react';
import { LogOnMount } from 'react-amplitude-hooks';

export const Loading = () => {
  return (
    <>
      <LogOnMount eventType="loading" />
      <div className="loading" />{' '}
    </>
  );
};
