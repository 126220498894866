import React, { useMemo } from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { Bullets } from '~pages/-feature/bullets/Bullets';
import { LegalBullet } from '~pages/-feature/bullets/LegalBullet';
import { SupportBullet } from '~pages/-feature/bullets/SupportBullet';

const Disclaimers = () => {
  const [state] = useContext();
  const {
    order: {
      item: { orderType, platform }
    }
  } = state;

  const keepPublic = useMemo((): string | undefined => {
    const prefix = 'Please keep your profile public to allow people to';
    switch (orderType) {
      case OrderType.Follow:
        return `${prefix} follow you!`;
      default:
        return undefined;
      // return `${prefix} engage with you!`;
    }
  }, [orderType]);

  const qualityNotice = useMemo((): string | undefined => {
    switch (platform) {
      case Platform.Tok:
        return undefined;
      // return `Please note, Instagram ${
      //   orderType === OrderType.Follow ? 'followers' : 'engagement'
      // } quality is higher than our TikTok offering.`;
      default:
        return undefined;
    }
  }, [platform, orderType]);

  const bullets = useMemo((): (string | typeof SupportBullet | typeof LegalBullet)[] => {
    let list: (string | typeof SupportBullet | typeof LegalBullet)[] = [LegalBullet];

    if (qualityNotice) list.push(qualityNotice);

    if (keepPublic) list.push(keepPublic);

    return list.reverse();
  }, [keepPublic, qualityNotice]);

  return <Bullets bullets={bullets} />;
};

export default Disclaimers;
