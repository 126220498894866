import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RouterLink)`
  box-shadow: none;

  &:active,
  &:focus,
  &:focus-within,
  &:target,
  &:hover,
  &:visited,
  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
`;
