import React, { useCallback, useEffect, useState } from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { GetPostsResponse } from '~models/api/GetPostsResponse';
import { UsernamePosts } from '~models/UsernamePosts';
import { Bullets } from '~pages/-feature/bullets/Bullets';
import { ErrorText } from '~pages/-feature/typography/Typography';
import Button from '~pages/main/Button';
import { getUid } from '~utils/cookie';
import { isValidUsernameFormat } from '~utils/isValidUsernameFormat';

const LoadPosts = () => {
  const [state, dispatch] = useContext();
  const [actionText, setActionText] = useState('Load Posts');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    order: {
      username,
      phone,
      item: { platform }
    },
    loadedPosts
  } = state;

  const {
    backend: { getPosts },
    order: {
      error: { setUsernameError }
    },
    setLoadedPosts
  } = dispatch;

  const loadPosts = useCallback(async () => {
    const isValid = isValidUsernameFormat(username, setUsernameError);
    if (!isValid) return;

    setLoading(true);

    try {
      const getPostsResponse: GetPostsResponse = await getPosts({
        uid: getUid(),
        platform,
        username,
        phone
      });

      if (getPostsResponse.error.userId) {
        setErrorText('please check the username is correct and try again');
      } else if (getPostsResponse.error.private) {
        setErrorText('please keep your profile public so we can find your posts');
      } else if (getPostsResponse.posts.length === 0) {
        setErrorText('could not find any posts for this username');
      } else {
        setErrorText('');
      }

      const { count, cursorMore } = getPostsResponse;

      const u: UsernamePosts = {
        platform,
        username,
        posts: getPostsResponse.posts,
        count,
        cursorMore
      };

      const others: UsernamePosts[] =
        loadedPosts?.filter((up) => up.username !== username || up.platform !== platform) ?? [];

      setLoadedPosts([...others, u]);
    } catch (e) {
      setErrorText('Oops: there was an issue communicating with our servers');
    }

    setLoading(false);
    setActionText('Load Again');
  }, [platform, username, phone, loadedPosts]);

  // reset text when username changes
  useEffect(() => {
    setErrorText('');
    setActionText('Load Posts');
  }, [username]);

  return (
    <>
      {errorText && <ErrorText style={{ textAlign: 'center', fontSize: 15, padding: 5 }}>{errorText}</ErrorText>}
      <Button onClickHandler={loadPosts} processing={loading} actionText={actionText} />
      <Bullets bullets={['Please keep your profile public so we can retrieve your posts.']} />
    </>
  );
};

export default LoadPosts;
