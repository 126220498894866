import { PaymentMethod } from '@stripe/stripe-js';
import axios, { AxiosResponse } from 'axios';
import { backendEndpoint } from '~constants';
import { CheckoutRequest } from '~models/api-request/CheckoutRequest';
import { PaymentIntentResponse } from '~models/api/PaymentIntentResponse';

export async function createPaymentIntent(
  purchaseData: CheckoutRequest,
  paymentMethod: PaymentMethod
): Promise<string> {
  console.log(purchaseData);
  const response: AxiosResponse<PaymentIntentResponse> = await axios.post(`${backendEndpoint}/api/stripe-direct-pay`, {
    ...purchaseData,
    paymentMethod
  });
  return response.data.clientSecret;
}
