export const formatNumber = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function formatCurrency(number: number): string {
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatCentsToDollars(cents: number): string {
  return (cents / 100).toFixed(2);
}
