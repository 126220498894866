import { Platform } from '~models/Platform';
import { getCookie, setCookie } from '~utils/cookie';

function extractPlatformCookie(): Platform {
  const cookie = getCookie('platform');
  // const campaign = getCookie('campaign');
  switch (cookie) {
    case 'TikTok':
      return Platform.Tok;
    case 'Instagram':
      return Platform.Gram;
    default:
      return Platform.Gram;
    // optimize for Tok when coming from Tok videos
    // return campaign === 'organic' ? Platform.Tok : Platform.Gram;
  }
}

function determinePlatform(platformParam: string | undefined): Platform {
  let platform: Platform;

  switch (platformParam?.toLowerCase()) {
    case 'instagram':
    case 'gram':
    case 'ig':
      platform = Platform.Gram;
      break;
    case 'tiktok':
    case 'tok':
      platform = Platform.Tok;
      break;
    default:
      platform = extractPlatformCookie();
  }

  setCookie('platform', platform);

  return platform;
}

export default determinePlatform;
