import React from 'react';
import { LogOnMount } from 'react-amplitude-hooks';
import { NavLink } from 'react-router-dom';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper, Title } from '~pages/-feature/typography/Typography';

type Props = {
  errorCode: string;
};

export const ErrorPage = (props: Props) => {
  return (
    <OuterWrapper>
      <LogOnMount eventType="page:error" />
      <InnerWrapper>
        <Top nav={'home'} />

        <Content style={{ paddingBottom: 20 }}>
          <Title style={{ fontSize: 22, paddingBottom: 30, paddingTop: 10 }}>
            Ooops... looks like an error occurred! ({props.errorCode})
          </Title>

          <NavLink
            style={{ ...linkStyle, textAlign: 'center', fontSize: 20 }}
            to={`/instagram/follows`}
            className="white"
          >
            Go Back Home <span className="logo-single" />
          </NavLink>
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
