const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const validPhoneNumber = (phone: string): boolean => {
  if (phone.length < 2) {
    return false;
  }
  // contains alphabet
  if (phone.match(/[A-Z]/gi)) {
    return false;
  }
  return phoneUtil.isValidNumber(phoneUtil.parse(phone, 'US'));
};
