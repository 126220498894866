import React from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { SectionTitle } from '~pages/-feature/typography/SectionTitle';

const Prompt = () => {
  const [state] = useContext();
  const {
    order: {
      item: { orderType, platform, qualityType }
    }
  } = state;

  const gender = 'Female';

  switch (orderType) {
    case OrderType.Follow:
      switch (platform) {
        case Platform.Gram:
          return <SectionTitle title={`${qualityType} ${gender} Followers`} subtitle="Choose how many you want:" />;
        case Platform.Tok:
          return <SectionTitle title={`TikTok Followers`} subtitle="Choose how many you want:" />;
      }
      break;
    case OrderType.Like:
      switch (platform) {
        case Platform.Gram:
          return <SectionTitle title={`${qualityType} ${gender} Likes`} subtitle="Choose how many you want:" />;
        case Platform.Tok:
          return <SectionTitle title={`TikTok Likes`} subtitle="Choose how many you want:" />;
      }
      break;
    case OrderType.View:
      switch (platform) {
        case Platform.Tok:
          return <SectionTitle title={`TikTok Views`} subtitle="Choose how many you want:" />;
        default:
          return <SectionTitle title={`${platform} Views`} subtitle="Choose how many you want:" />;
      }
    case OrderType.Comment:
      switch (platform) {
        case Platform.Gram:
          return <SectionTitle title={`${qualityType} ${gender} Comments`} subtitle="Choose how many you want:" />;
        case Platform.Tok:
          return <SectionTitle title={`TikTok Comments`} subtitle="Choose how many you want:" />;
      }
      break;
    case OrderType.Share:
      return <SectionTitle title={`${platform} Shares`} subtitle="Choose how many you want:" />;
    default:
      console.error('cannot match orderType');
      return <div />;
  }
};

export default Prompt;
