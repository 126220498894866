import axios, { AxiosResponse } from 'axios';
import { backendEndpoint, stripePromise } from '~constants';
import { CheckoutRequest } from '~models/api-request/CheckoutRequest';
import { CheckoutResponse } from '~models/api/CheckoutResponse';

export async function initiatePurchase(purchaseData: CheckoutRequest): Promise<void> {
  try {
    const response: AxiosResponse<CheckoutResponse> = await axios.post(`${backendEndpoint}/api/stripe-checkout`, {
      ...purchaseData
    });

    if (response.status === 200) {
      const stripe = await stripePromise;

      const redirectResult = await stripe?.redirectToCheckout({
        sessionId: response?.data?.id
      });

      if (redirectResult?.error?.message) {
        alert(redirectResult?.error?.message);
      }
    } else {
      console.error('create checkout session failed');
    }
  } catch (e) {
    console.error(e);
    return e;
  }
}
