import React, { useEffect } from 'react';
import { LogOnMount } from 'react-amplitude-hooks';
import { supportEmail, supportPhone } from '~constants';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper, Paragraph, Title } from '~pages/-feature/typography/Typography';
import { Page, trackPageView } from '~utils/tracking';

export const PageRefund = () => {
  useEffect(() => trackPageView(Page.refund), []);

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:refund" />
      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <Top nav={'home'} />
        <Content style={{ textAlign: 'left' }}>
          <Title>Refund Policy</Title>

          <Paragraph style={{ fontSize: 18, fontWeight: 700, paddingTop: 15 }}>
            YoFame’s Refund and Cancellation Policy
          </Paragraph>

          <Paragraph>
            Since yofame.com is offering non-tangible irrevocable goods,{' '}
            <b>we do not issue refunds once the order is accomplished and the product is sent</b>. Orders may be filled
            as quickly as 5 minutes from being placed, but often will take a few hours to both start and complete. As a
            customer, you are responsible for understanding this upon purchasing any item on our site.
          </Paragraph>

          <Paragraph>
            However, we realize that exceptional circumstances can take place with regards to the character of the
            product we supply. Therefore, we DO honor requests for a refund for the following reasons:
          </Paragraph>

          <ul>
            <li>
              <b>Non-delivery of the product:</b> in some cases, the process times are slower, and it may take a little
              longer for your orders to finish. In this case, we recommend contacting us for assistance. Claims for
              non-delivery must be submitted to our support department in writing within 72 hours from the order placing
              the date. Otherwise, the campaign will be considered completed. If your order delivery has started, then
              the delivery will be finished as soon as possible and no refunds will be issued once your order has
              started processing.
            </li>

            <br />

            <li>
              <b>Product not-as-described:</b> such issues should be reported to our support department within 72 hours
              from the date of the purchase. Clear evidence must be provided proving that the purchased product is not
              as it is described on the website.
            </li>
          </ul>

          <Paragraph>
            Our support team is always eager to assist you and deliver highly professional support in a timely manner.
            Thank you for purchasing from us.
          </Paragraph>

          <Paragraph>
            If you have any questions, please get in touch by texting us at{' '}
            <a style={linkStyle} href={`tel:${supportPhone}`}>
              {supportPhone}
            </a>{' '}
            or emailing us at{' '}
            <a style={linkStyle} href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>
            . Please allow up to 24 hours for a reply. We promise to get back to you.
          </Paragraph>
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
