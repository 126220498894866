import { Config, ConfigOptionItem } from '~models/Config';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { QualityType } from '~models/QualityType';
import { getCookie, setCookie } from '~utils/cookie';

interface DetermineItem {
  remoteConfig: Config;
  platform: Platform;
  orderType: OrderType;
  qualityType: QualityType;
}

function determineItem({ remoteConfig, platform, orderType, qualityType }: DetermineItem): ConfigOptionItem {
  const cookieKey = `${platform}:${orderType}:${qualityType}`;
  const cookieVal = parseInt(getCookie(cookieKey) ?? '-1');

  const candidates = remoteConfig?.options?.filter((i) => {
    return i.platform === platform && i.orderType === orderType && i.qualityType === qualityType;
  });

  // has previously set cookie
  if (cookieVal !== -1) {
    const x = candidates.filter((i) => i.quantity === cookieVal);
    if (x.length > 0) {
      return x[0];
    }
  }

  if (candidates.length === 0) {
    throw `no valid candidates for ${platform} ${qualityType} ${orderType}`;
  }

  setCookie(cookieKey, candidates[0].quantity);
  return candidates[0];
}

export default determineItem;
