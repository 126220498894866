import React, { useCallback, useEffect, useMemo } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import ReactPixel from 'react-snapchat-pixel';
import tiktok from 'react-tiktok-helper';
import styled from 'styled-components';
import { supportEmail, supportPhone } from '~constants';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { linkStyle } from '~pages/-feature/bullets/Bullets';
import { getLink } from '~pages/-feature/menu/MenuSection';
import { Top } from '~pages/-feature/Top';
import { Link } from '~pages/-feature/typography/Link';
import { Content, InnerWrapper, OuterWrapper, SubTitle } from '~pages/-feature/typography/Typography';
import { Page, trackPageView } from '~utils/tracking';

const Title = styled.h1`
  letter-spacing: -0.7px;
  font-size: var(--yf-font-size--70, 28px);
  line-height: var(--yf-line-height--70, 36px);
  font-family: var(--yf-font-combined--medium);
  font-weight: var(--yf-heading--font_weight);
  color: var(--yf-heading--color);
  transition: font 0.33s ease, color 0.33s ease, opacity 0.33s ease;
`;

export const ThanksPage = () => {
  const [state] = useContext();
  const { logEvent } = useAmplitude();

  const {
    config: { cid, flow },
    track: { campaign },
    order: {
      item: { platform, orderType, qualityType, quantity },
      cents,
      posts,
      interval
    }
  } = state;

  const tapSupportPhone = useCallback(() => {
    logEvent('tap_support_phone', { supportPhoneNumber: supportPhone });
  }, []);

  useEffect(() => trackPageView(Page.thanks), []);

  useEffect(() => {
    logEvent('purchase', {
      vendor: 'Stripe',
      cid,
      platform,
      campaign,
      cents,
      interval,
      orderType,
      qualityType,
      quantity,
      flow
    });

    ReactPixel.pageView(); // For tracking page view
    ReactPixel.track('PURCHASE', {
      price: cents,
      currency: 'USD',
      item_category: platform,
      description: `${platform} ${orderType} ${quantity} for ${cents}`
    });

    tiktok.event('CompletePayment', {
      content_type: 'product',
      content_id: `${platform}_${orderType}_${quantity}x_¢${cents}`.toLowerCase(),
      quantity,
      currency: 'USD',
      value: cents,
      price: posts?.length ?? 0 > 0 ? cents / posts.length : cents
    });
  }, []);

  const timeTakes = useMemo(() => {
    switch (platform) {
      case Platform.Gram:
        return 'a few hours';
      case Platform.Tok:
        return 'a day';
    }
  }, [platform]);

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:thanks" />
      <InnerWrapper style={{ minHeight: window.innerHeight }}>
        <Top />

        <Content style={{ paddingBottom: 20 }}>
          <Title style={{ textAlign: 'left', paddingBottom: 15 }}>Thank you!</Title>

          <SubTitle textAlign={'left'} padding={'10px 0 15px 0'}>
            Thank you for your order and welcome to the YoFame family!
          </SubTitle>

          <SubTitle textAlign={'left'} padding={'10px 0 15px 0'}>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              Important: <u>Please make sure your profile is public until your order is finished being filled.</u>
            </span>
          </SubTitle>

          <SubTitle textAlign={'left'} padding={'10px 0 15px 0'}>
            Orders typically take {timeTakes} to be filled.
          </SubTitle>

          <SubTitle textAlign={'left'} padding={'10px 0 15px 0'}>
            Please text us at{' '}
            <a style={linkStyle} href={`tel:${supportPhone}`} onClick={tapSupportPhone}>
              {supportPhone}
            </a>{' '}
            or email us at{' '}
            <a style={linkStyle} href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>{' '}
            if you have any questions.
          </SubTitle>

          {orderType === OrderType.Follow && (
            <SubTitle textAlign={'left'} padding={'10px 0 15px 0'}>
              Did you know we also offer authentic{' '}
              <Link style={linkStyle} to={getLink(OrderType.Like, platform)}>
                likes
              </Link>{' '}
              and{' '}
              <Link style={linkStyle} to={getLink(OrderType.Comment, platform)}>
                comments
              </Link>{' '}
              to boost your posts? Our menu on the top right contains all the goodies!
            </SubTitle>
          )}
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
