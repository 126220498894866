import React from 'react';
import styled from 'styled-components';
import { LegalBullet } from '~pages/-feature/bullets/LegalBullet';
import { SupportBullet } from '~pages/-feature/bullets/SupportBullet';

const Wrapper = styled.section`
  margin-top: 5px;
  text-align: left;
  --yf-text--color: var(--yf-color--grey30);
  color: var(--yf-text--color);
`;

interface Props {
  bullets: (string | typeof SupportBullet | typeof LegalBullet)[];
}

export const linkStyle = {
  color: 'var(--yf-color--blue30)',
  fontFamily: 'var(--yf-font-combined--medium)',
  boxShadow: 'none'
};

export const Bullets = (props: Props) => {
  const { bullets } = props;

  return (
    <Wrapper role="presentation">
      <ol className="yf-list yf-list--unordered text-loader--list text-loader--list_items">
        {bullets.map((bullet, index) => {
          if (bullet === SupportBullet) {
            return <SupportBullet key={index} />;
          } else if (bullet === LegalBullet) {
            return <LegalBullet key={index} />;
          } else {
            return (
              <li className="yf-list-item text-loader--list_element" key={index}>
                <span className="text-loader--content text-loader--list_element--text">{bullet}</span>
              </li>
            );
          }
        })}
      </ol>
    </Wrapper>
  );
};
