export function isValidUsernameFormat(username: string, setUsernameError: (error: string) => void): boolean {
  if (!username || username.trim().length === 0) {
    setUsernameError('This field is required');
    return false;
  } else if (username.indexOf('@') !== -1) {
    setUsernameError('Please remove @');
    return false;
  } else if (username.indexOf(' ') !== -1) {
    setUsernameError(`Usernames have no spaces`);
    return false;
  }
  return true;
}
