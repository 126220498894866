import { CheckoutLineItem } from '~models/api-request/CheckoutRequest';
import { ConfigOptionItem } from '~models/Config';
import { PriceInterval } from '~models/PriceInterval';

interface ExtractLineItems {
  optionItems: ConfigOptionItem[];
  interval: PriceInterval;
}

export function extractLineItems({ optionItems, interval }: ExtractLineItems): CheckoutLineItem[] {
  return optionItems.map((option) => {
    const { orderType, qualityType, quantity, cents } = option;
    return {
      interval,
      orderType,
      qualityType,
      quantity,
      cents
    };
  });
}
