import { Divider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Platform } from '~models/Platform';
import { MenuSection } from '~pages/-feature/menu/MenuSection';
import sortMenuItems from '~pages/-feature/menu/sortMenuItems';

const CloseWrapper = styled.div`
  text-align: right;
  padding: 15px 15px 0 0;
`;

const DrawerWrapper = styled.div`
  width: 220px;
`;

export const Menu = () => {
  const [state, dispatch] = useContext();
  const [open, setOpen] = useState(false);

  const [gramMenuTypes, setGramMenuTypes] = useState([] as OrderType[]);
  const [tokMenuTypes, setTokMenuTypes] = useState([] as OrderType[]);

  useEffect(() => {
    state.config.remoteConfig?.options.map((o) => {
      if (o.platform === Platform.Gram) {
        if (gramMenuTypes.indexOf(o.orderType) === -1) {
          setGramMenuTypes([o.orderType, ...gramMenuTypes].sort(sortMenuItems));
        }
      }
      if (o.platform === Platform.Tok) {
        if (tokMenuTypes.indexOf(o.orderType) === -1) {
          setTokMenuTypes([o.orderType, ...tokMenuTypes].sort(sortMenuItems));
        }
      }
    });
  }, [state.config.remoteConfig, gramMenuTypes, tokMenuTypes]);

  const toggleDrawer = (val: boolean) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setOpen(val);
  };

  return (
    <>
      <MenuIcon style={{ fontSize: 25 }} onClick={toggleDrawer(true)} />
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <DrawerWrapper role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <CloseWrapper>
            <CloseIcon style={{ fontSize: 27 }} onClick={toggleDrawer(false)} />
          </CloseWrapper>

          {gramMenuTypes.length > 0 && <MenuSection platform={Platform.Gram} orderTypes={gramMenuTypes} />}

          {gramMenuTypes.length > 0 && tokMenuTypes.length > 0 && <Divider style={{ marginBottom: 10 }} />}

          {tokMenuTypes.length > 0 && <MenuSection platform={Platform.Tok} orderTypes={tokMenuTypes} />}

          {/*{gramMenuTypes.length > 0 && <Divider style={{ marginBottom: 10 }} />}*/}

          {/*<MenuSection platform={Platform.Company} orderTypes={[OrderType.Mission]} />*/}
        </DrawerWrapper>
      </Drawer>
    </>
  );
};
