export const theme = {
  a: {
    flow: 'a',
    background: 'white',
    textColor: 'black'
  },
  b: {
    flow: 'b',
    background: 'white',
    textColor: 'black'
  }
};
