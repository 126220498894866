import { OrderType } from '~models/OrderType';
import { getCookie, setCookie } from '~utils/cookie';

function extractOrderTypeCookie(): OrderType {
  const cookie = getCookie('orderType');
  switch (cookie) {
    case 'Follow':
      return OrderType.Follow;
    case 'Like':
      return OrderType.Like;
    case 'View':
      return OrderType.View;
    case 'Comment':
      return OrderType.Comment;
    case 'Share':
      return OrderType.Share;
    default:
      return OrderType.Follow;
  }
}

function determineOrderType(orderTypeParam: string | undefined): OrderType {
  let orderType: OrderType;

  switch (orderTypeParam?.toLowerCase()) {
    case 'follow':
    case 'follows':
    case 'follower':
    case 'followers':
      orderType = OrderType.Follow;
      break;
    case 'like':
    case 'likes':
      orderType = OrderType.Like;
      break;
    case 'view':
    case 'views':
      orderType = OrderType.View;
      break;
    case 'comment':
    case 'comments':
      orderType = OrderType.Comment;
      break;
    case 'share':
    case 'shares':
      orderType = OrderType.Share;
      break;
    default:
      orderType = extractOrderTypeCookie();
  }

  setCookie('orderType', orderType);

  return orderType;
}

export default determineOrderType;
