import { useMemo } from 'react';
import { PaymentRequest } from 'types/apple-pay';
import { getUid } from '~utils/cookie';
import { formatCentsToDollars } from '~utils/format';

/**
 * Generate the Apple Pay payment request object
 */
export const useApplePaymentRequest = ({ totalCents, order, cid, campaign }): PaymentRequest => {
  return useMemo(() => {
    return {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      requiredShippingContactFields: ['name', 'phone', 'email'], // do we need phone?
      total: {
        label: 'YoFame Boost',
        amount: formatCentsToDollars(totalCents)
      },
      applicationData: JSON.stringify({
        order,
        totalCents,
        uid: getUid(),
        cid,
        campaign
      })
    };
  }, [totalCents, order, cid, campaign]);
};
