/**
 * Is apple pay supported?
 */
export const useApplePaySupported = (): boolean => {
  // NOTE: don't use useMemo since ApplePaySession is defined later
  // return useMemo(() => {
  // check supported
  if (typeof ApplePaySession === 'undefined') return false;
  return ApplePaySession.canMakePayments();
  // }, []);
};
