import axios, { AxiosResponse } from 'axios';
import { backendEndpoint } from '~constants';
import { BasicAxiosResponse } from '~models/api/BasicAxiosResponse';
import { SignInData } from '~models/SignInData';

export async function sendSignInLink(signInData: SignInData): Promise<string> {
  const result: AxiosResponse<BasicAxiosResponse> = await axios.post(`${backendEndpoint}/api/send-sign-in-link`, {
    ...signInData
  });

  if (result?.status === 400 && result?.data?.message) {
    alert(result?.data?.message);
  }

  return result?.data?.message ?? '';
}
