import { useCallback, useEffect } from 'react';
import { UseApplePayConfigs } from 'types/apple-pay';
import { useApplePaySupported } from '~hooks/apple-pay/useApplePaySupported';
import { usePrepaymentValidation } from '~hooks/apple-pay/usePrepaymentValidation';

/**
 * Useful Apple Docs:
 * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/creating_an_apple_pay_session
 *
 * Credit:
 * https://github.com/midoghranek/use-apple-pay/blob/master/src/hooks/useApplePay.ts
 *
 * @param paymentRequest
 * @param onValidateMerchant
 * @param onPaymentAuthorized
 * @param onCancel
 * @param dependencies
 */
export const useApplePay = ({
  paymentRequest,
  onValidateMerchant,
  onPaymentAuthorized,
  onCancel,
  dependencies = []
}: UseApplePayConfigs) => {
  const applePaySupported = useApplePaySupported();
  const prePaymentValidation = usePrepaymentValidation();

  const onRequestApplePay: VoidFunction = useCallback(() => {
    // check for support
    if (!applePaySupported) return console.log('!applePaySupported');

    // create session
    const session = new ApplePaySession(3, paymentRequest);

    // set important functions
    session.onvalidatemerchant = (event) => onValidateMerchant(event, session);
    session.onpaymentauthorized = (event) => onPaymentAuthorized(event, session);
    session.oncancel = (event) => onCancel(event, session);

    // perform validation actions
    const passed = prePaymentValidation();

    if (passed) {
      // trigger flow
      session.begin();
    }
  }, [...dependencies]);

  // event handler
  useEffect(() => {
    document.querySelector('apple-pay-button')?.addEventListener('click', onRequestApplePay);
    return () => document.querySelector('apple-pay-button')?.removeEventListener('click', onRequestApplePay);
  }, [onRequestApplePay, ...dependencies]);

  return { applePayClickHandler: onRequestApplePay };
};
