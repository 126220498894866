import React from 'react';
import styled from 'styled-components';
import paymentMethods from '~assets/img/payment-methods.png';

const Wrapper = styled.div``;

const PaymentMethods = styled.img`
  height: 50px;
  margin: 0 auto 50px auto;
  justify-content: center;
  display: flex;
`;

export const CreditCards = () => {
  return (
    <Wrapper>
      <PaymentMethods src={paymentMethods} alt="payment methods" />;
    </Wrapper>
  );
};
