import React, { useCallback } from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { Platform } from '~models/Platform';
import TextInput from '~pages/-feature/form/TextInput';

interface Props {}

const Username = ({}: Props) => {
  const [state, dispatch] = useContext();

  const {
    order: {
      item: { platform },
      username,
      error: { usernameError },
      cookie: { usernameCookieKey }
    }
  } = state;

  const {
    order: {
      setUsername,
      error: { setUsernameError }
    }
  } = dispatch;

  const onChangeHandler = useCallback(
    (val) => {
      setUsername(val?.toLowerCase()?.trim());
    },
    [username, setUsername]
  );

  return (
    <TextInput
      cookieKey={usernameCookieKey}
      labelText="Username"
      inputId={`${platform.toLowerCase()}_handle`}
      maxLength={50}
      igIcon={platform === Platform.Gram}
      tokIcon={platform === Platform.Tok}
      value={username}
      errorText={usernameError}
      setError={setUsernameError}
      onChange={onChangeHandler}
    />
  );
};

export default Username;
