import { PaymentRequest } from '@stripe/stripe-js';
import { Config, ConfigOptionItem } from '~models/Config';
import { Post } from '~models/Post';
import { PriceInterval } from '~models/PriceInterval';
import { StateI } from '~models/State';
import { UsernamePosts } from '~models/UsernamePosts';

interface InitializeState {
  config: {
    cid: string;
    flow: string; // "a" for original or "b" for new step flow
    remoteConfig?: Config;
  };
  track: {
    campaign: string;
  };
  order: {
    interval: PriceInterval;
    item: ConfigOptionItem;
    cents: number;
    username: string;
    phone: string;
    posts: Post[];
    error: {
      centsError: string;
      generalError: string;
      usernameError: string;
      phoneError: string;
    };
    cookie: {
      centsCookieKey: string;
      usernameCookieKey: string;
      phoneCookieKey: string;
    };
  };
  loadedPosts: UsernamePosts[];
  backend: { inFlight: boolean };
  payment: {
    paymentRequest: PaymentRequest;
  };
}

function initializeState({
  config: { cid, flow, remoteConfig },
  track: { campaign },
  order: {
    interval,
    item,
    cents,
    username,
    phone,
    posts,
    error: { centsError, generalError, usernameError, phoneError },
    cookie: { centsCookieKey, usernameCookieKey, phoneCookieKey }
  },
  loadedPosts,
  backend: { inFlight },
  payment: { paymentRequest }
}: InitializeState): StateI {
  return {
    config: {
      cid,
      flow,
      remoteConfig
    },
    track: {
      campaign
    },
    order: {
      interval,
      item,
      cents,
      username,
      phone,
      posts,
      error: {
        centsError,
        generalError,
        usernameError,
        phoneError
      },
      cookie: {
        centsCookieKey,
        usernameCookieKey,
        phoneCookieKey
      }
    },
    loadedPosts,
    backend: { inFlight },
    payment: {
      paymentRequest
    }
  };
}

export default initializeState;
