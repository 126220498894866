import gtag from 'ga-gtag';
import React, { useCallback } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import { supportEmail, supportPhone } from '~constants';
import { linkStyle } from '~pages/-feature/bullets/Bullets';

export const SupportBullet = () => {
  const { logEvent } = useAmplitude();

  const tapSupportPhone = useCallback(() => {
    gtag('event', 'tap_support_phone');
    logEvent('tap_support_phone', { supportPhoneNumber: supportPhone });
  }, []);

  const tapSupportEmail = useCallback(() => {
    gtag('event', 'tap_support_email');
    logEvent('tap_support_email', { supportEmail });
  }, []);

  return (
    <li className="yf-list-item text-loader--list_element" key={'legal'}>
      <span className="text-loader--content text-loader--list_element--text">
        You can also text us at&nbsp;
        <a style={linkStyle} href={`tel:${supportPhone}`} onClick={tapSupportPhone}>
          {supportPhone}
        </a>
        {/*&nbsp;or email us at&nbsp;*/}
        {/*<a style={linkStyle} href={`mailto:${supportEmail}`} onClick={tapSupportEmail}>*/}
        {/*  {supportEmail}*/}
        {/*</a>*/}
        .&nbsp;
        {/*if you need direct support. */}
        Please allow up to 24 hours for a reply. We promise to get back to you.
      </span>
    </li>
  );
};
