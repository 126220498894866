import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const Tabs = styled.div`
  display: block;
  width: auto;
  position: relative;
  margin: var(--yf-size) 0;
  margin-bottom: 25px;
`;

const TabsList = styled.nav`
  display: flex;
  margin: 0 -4px;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: var(--yf-color--grey70);
  border-radius: var(--yf-border-radius--pill);
  position: relative;
  overflow: hidden;
  z-index: 0;
  --tabnumber: -1;
  --tablength: 1;
  --tabWidth: 0;
  --tabLeft: 0;

  :before {
    content: '';
    position: absolute;
    background-color: var(--yf-color--white);
    margin: var(--yf-size--half) 0;
    left: calc(var(--tabLeft) + var(--yf-size--half));
    width: calc(var(--tabWidth) - var(--yf-size--half));
    height: var(--yf-tabs-height);
    border-radius: var(--yf-border-radius--pill);
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  }
`;

const Tab = styled.button`
  display: inline-flex;
  height: var(--yf-tabs-height);
  align-items: center;
  justify-content: center;
  margin: var(--yf-size--half);
  font-family: var(--yf-font-combined--medium);
  font-size: var(--yf-font-size--20);
  font-weight: 500;
  text-align: center;
  color: var(--yf-color--grey30);
  background-color: transparent;
  border-radius: var(--yf-border-radius--pill);
  flex-grow: 1;
  white-space: nowrap;
  scroll-snap-align: center;
  box-shadow: inset 0 0 0 0 transparent, 0 0 0 0 transparent;
  position: relative;
  padding: 0 var(--yf-size--1x);
  border: none;
  outline: none;
  transition: var(--yf-transition--tab);

  &:focus,
  &:hover {
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
    border: none;
    outline: none;
    transition: var(--yf-transition--tab);
  }

  &[aria-selected='true'],
  &[aria-selected='true']:hover,
  &[aria-selected='true']:focus {
    color: var(--yf-color--black);
    background-color: var(--yf-color--white);
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  }
`;

interface Props {
  leftText: string;
  rightText: string;
  onSelected: (index: number) => void;
  enableBoth?: boolean;
  initialSelected?: 0 | 1 | number;
}

export const Toggler = (props: Props) => {
  const { leftText, rightText, onSelected, enableBoth, initialSelected } = props;
  const [selected, setSelected] = useState(initialSelected || 0);

  const selectLeft = useCallback(() => {
    setSelected(0);
    onSelected(0);
  }, [onSelected]);

  const selectRight = useCallback(() => {
    setSelected(1);
    onSelected(1);
  }, [onSelected]);

  const selectBoth = useCallback(() => {
    setSelected(2);
    onSelected(2);
  }, [onSelected]);

  const bothText = 'Both';

  return (
    <Tabs>
      <TabsList role="tablist" aria-label="Tabs">
        <Tab
          id={`${leftText}-tab`}
          aria-controls={leftText}
          aria-label={leftText}
          aria-selected={selected === 0}
          onClick={selectLeft}
          data-tabnumber={0}
          tabIndex={0}
          role="tab"
          type="button"
        >
          {leftText}
        </Tab>
        <Tab
          id={`${rightText}-tab`}
          aria-controls={rightText}
          aria-label={rightText}
          aria-selected={selected === 1}
          data-tabnumber={1}
          tabIndex={1}
          onClick={selectRight}
          role="tab"
          type="button"
        >
          {rightText}
        </Tab>
        {enableBoth && (
          <Tab
            id={`${bothText}-tab`}
            aria-controls={bothText}
            aria-label={bothText}
            aria-selected={selected === 2}
            data-tabnumber={2}
            tabIndex={2}
            onClick={selectBoth}
            role="tab"
            type="button"
          >
            {bothText}
          </Tab>
        )}
      </TabsList>
    </Tabs>
  );
};
