import React from 'react';
import styled from 'styled-components';
import { Post } from '~models/Post';
import PostElement from '~pages/main/posts/PostElement';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

interface Props {
  postList: Post[];
}

const PostsWrapper = ({ postList }: Props) => {
  return (
    <Wrapper>
      {postList.map((p) => {
        return <PostElement key={p.shortcode} post={p} />;
      })}
    </Wrapper>
  );
};

export default PostsWrapper;
