import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { stripePromise } from '~constants';
import { useApplePaySupported } from '~hooks/apple-pay/useApplePaySupported';
import { useContext } from '~hooks/useContext/useContext';
import { useIsTesting } from '~hooks/useIsTesting';
import { Loading } from '~pages/-feature/Loading';
import { ErrorText, SubTitle } from '~pages/-feature/typography/Typography';
import Disclaimers from '~pages/main/Disclaimers';
import ApplePay from '~pages/main/order/ApplePay';
import Order from '~pages/main/order/Order';

const OrderWrapper = () => {
  const [state] = useContext();

  const isTesting = useIsTesting();
  const applePaySupported = useApplePaySupported();
  const googlePaySupported = useApplePaySupported();

  const inFlight = state.backend.inFlight;
  const generalError = state.order.error.generalError;
  const centsError = state.order.error.centsError;

  return (
    <>
      {inFlight && <SubTitle>Please wait..</SubTitle>}
      {inFlight && <Loading />}
      {generalError && <ErrorText style={{ textAlign: 'center', fontSize: 15, padding: 5 }}>{generalError}</ErrorText>}
      {centsError && <ErrorText style={{ textAlign: 'center', fontSize: 15, padding: 5 }}>{centsError}</ErrorText>}

      {isTesting ? (
        <>
          {applePaySupported && <ApplePay />}

          {!applePaySupported && !googlePaySupported && (
            <Elements stripe={stripePromise}>
              <Order />
            </Elements>
          )}
        </>
      ) : (
        <>
          {applePaySupported && <ApplePay />}

          {!applePaySupported && !googlePaySupported && (
            <Elements stripe={stripePromise}>
              <Order />
            </Elements>
          )}
        </>
      )}

      <Disclaimers />
    </>
  );
};

export default OrderWrapper;
