import gtag from 'ga-gtag';
import ReactPixel from 'react-snapchat-pixel';
import { getCookie } from '~utils/cookie';

export function trackBeginCheckout({ cents, platform, username, campaign, optionItems, logEvent }) {
  const description = `${JSON.stringify(optionItems)} for $${(cents / 100).toFixed(2)}`;

  gtag('event', 'begin_checkout', {
    value: cents,
    currency: 'USD',
    items: [
      {
        id: platform,
        name: description,
        category: platform,
        quantity: 1,
        price: cents
      }
    ]
  });

  logEvent('begin_checkout', {
    cid: getCookie('cid'),
    platform,
    optionItems: JSON.stringify(optionItems),
    cents,
    username,
    campaign
  });

  ReactPixel.track('START_CHECKOUT', {
    price: cents,
    currency: 'USD',
    item_category: platform,
    description
  });
}
