import React, { useCallback, useEffect, useMemo } from 'react';
import { LogOnMount, useAmplitude } from 'react-amplitude-hooks';
import { useContext } from '~hooks/useContext/useContext';
import { OrderType } from '~models/OrderType';
import { Bottom } from '~pages/-feature/Bottom';
import { RadioContainer } from '~pages/-feature/radio/RadioContainer';
import { Top } from '~pages/-feature/Top';
import { Content, InnerWrapper, OuterWrapper } from '~pages/-feature/typography/Typography';
import OrderWrapper from '~pages/main/order/OrderWrapper';
import Prompt from '~pages/main/Prompt';
import SelectPosts from '~pages/main/posts/SelectPosts';
import Username from '~pages/main/Username';
import { setCookie } from '~utils/cookie';
import { Page, trackPageView } from '~utils/tracking';

export const MainPage = () => {
  const { logEvent } = useAmplitude();
  const [state, dispatch] = useContext();

  const {
    config: { remoteConfig },
    order: {
      item: { platform, orderType, quantity, qualityType, cents },
      cookie: { centsCookieKey }
    }
  } = state;

  useEffect(() => {
    setCookie(centsCookieKey, cents);
    dispatch.order.setCents(cents);
  }, [cents]);

  useEffect(() => trackPageView(Page.home), []);

  const radioValues = useMemo((): number[] => {
    return (
      remoteConfig?.options
        .filter((i) => i.platform === platform && i.orderType === orderType && i.qualityType === qualityType)
        .map((i) => i.quantity) ?? []
    );
  }, [remoteConfig, platform, orderType, qualityType]);

  const updateItem = useCallback(
    (newQty: number) => {
      remoteConfig?.options.forEach((option) => {
        if (
          option.platform === platform &&
          option.orderType === orderType &&
          option.qualityType === qualityType &&
          option.quantity === newQty
        ) {
          dispatch.order.setItem(option);
        }
      });
    },
    [remoteConfig, platform, orderType, qualityType]
  );

  const onRadioSelect = useCallback(
    (qty: number) => {
      updateItem(qty);
      setCookie(`${platform}:${orderType}:${qualityType}`, qty);
      logEvent(`select:${orderType.toLowerCase()}s`, { platform, qty });
    },
    [platform, orderType, qualityType]
  );

  return (
    <OuterWrapper>
      <LogOnMount eventType="page:home" />
      <InnerWrapper>
        <Top />

        <Content style={{ paddingBottom: orderType === OrderType.View ? '100px' : '70px' }}>
          <Prompt />

          <RadioContainer
            selectedValue={quantity}
            values={radioValues}
            onSelect={onRadioSelect}
            id={`${platform}${orderType}${qualityType}`}
          />

          <Username />

          {orderType === OrderType.Follow ? <OrderWrapper /> : <SelectPosts />}
        </Content>

        <Bottom />
      </InnerWrapper>
    </OuterWrapper>
  );
};
