import { useEffect, useState } from 'react';
import { useContext } from '~hooks/useContext/useContext';
import { getCookie, setCookie } from '~utils/cookie';

/**
 * So we can test on iPhone before going live (eg. for new payment flows).
 *
 * Enter in yofame.test as username to trigger.
 */
export const useIsTesting = () => {
  const [state] = useContext();

  // keep track of local state
  const [isTesting, setIsTesting] = useState(getCookie('isTesting') === 'true' ?? false);

  // magic username inputs enable or disable testing
  useEffect(() => {
    if (state.order.username === 'yofame.test') {
      // on reload will still be testing and can use legit usernames
      setCookie('isTesting', true);
      // update local state
      setIsTesting(true);
    } else if (state.order.username === 'yofame.live') {
      // on reload will turn off testing and can use legit usernames
      setCookie('isTesting', false);
      // update local state
      setIsTesting(false);
    }
  }, [state.order.username]);

  return isTesting;
};
