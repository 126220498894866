import axios, { AxiosResponse } from 'axios';
import { backendEndpoint } from '~constants';
import { InfoCollectRequest } from '~models/api-request/InfoCollectRequest';
import { BasicAxiosResponse } from '~models/api/BasicAxiosResponse';

export const infoCollect = async ({ cid, uid, platform, username, phone }: InfoCollectRequest): Promise<boolean> => {
  const result: AxiosResponse<BasicAxiosResponse> = await axios.post(`${backendEndpoint}/api/info`, {
    cid,
    uid,
    platform,
    username,
    phone
  });
  return result.data.success;
};
