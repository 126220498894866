import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactPixel from 'react-snapchat-pixel';
import tiktok from 'react-tiktok-helper';
import { snapPixelId, tiktokPixelId } from '~constants';
import Routing from '~routing';

const firebaseConfig = {
  apiKey: 'AIzaSyCwY-1T2-h-XnL8s-_wEqm_hQpPoVVCXRE',
  authDomain: 'yofame.firebaseapp.com',
  projectId: 'yofame',
  storageBucket: 'yofame.appspot.com',
  messagingSenderId: '679822765323',
  appId: '1:679822765323:web:ca6fb270566a78ee86460e',
  measurementId: 'G-64G6DBHEGB'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Pixel
ReactPixel.init(snapPixelId);
tiktok.config(tiktokPixelId);

const App = () => (
  <Router>
    <Routing />
  </Router>
);

render(<App />, document.getElementById('root'));
